import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CandidateStatus, InterviewData, InterviewDetails } from '../models';
import {
  deleteInterviewAPI,
  getInterviewDataAPI,
  interviewAcceptanceStatusUpdateAPI,
  interviewDetailsAPI,
  updateCandidateStatusAPI,
  updateInterviewDetailsAPI
} from '../apis/InterviewsAPI';
import { RootState } from 'src/store/reducer';
import {
  Pagination,
  PaginationResponseDto,
  initalPaginationData
} from 'src/core/model/pagination.dto';
import {
  INTERVIEW_ACCEPTANCE_STATUS,
  ROLES
} from 'src/shared/constants/constants';
import { Action } from 'history';
import { downloadDocumentAPI } from 'src/modules/user-management/apis/UserManagementAPI';

export type InterviewsSliceData = {
  interviewsList: PaginationResponseDto<InterviewData>;
  interviewDetails: InterviewDetails;
  isError: boolean;
  errorMessage: string;
  isSuccess: boolean;
  successMessage: string;
  activeTabId: number;
};

const initialState: InterviewsSliceData = {
  interviewsList: initalPaginationData,
  interviewDetails: null,
  isError: false,
  errorMessage: '',
  isSuccess: false,
  successMessage: '',
  activeTabId: 0
};

export const selectInterviewsSliceData = ({ interviews }: RootState) => ({
  interviewsList: interviews?.interviewsList,
  interviewDetails: interviews?.interviewDetails,
  isError: interviews?.isError,
  errorMessage: interviews?.errorMessage,
  isSuccess: interviews?.isSuccess,
  successMessage: interviews?.successMessage,
  activeTabId: interviews?.activeTabId
});

export const getInterviewData = createAsyncThunk(
  'interviewsData/getInterviewData',
  async (
    payload: {
      paginationData: Pagination;
      filter: string;
      candidateId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getInterviewDataAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const interviewAcceptanceStatusUpdate = createAsyncThunk(
  'interviewsData/interviewAcceptanceStatusUpdate',
  async (payload: { id: string; status: string }, { rejectWithValue }) => {
    try {
      const response = await interviewAcceptanceStatusUpdateAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInterview = createAsyncThunk(
  'interviewsData/deleteInterview',
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const response = await deleteInterviewAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewDetails = createAsyncThunk(
  'interviewsData/getInterviewDetails',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await interviewDetailsAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateInterviewDetails = createAsyncThunk(
  'interviewsData/updateInterviewDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateInterviewDetailsAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUserProfilePic = (userId: string): Promise<string> => {
  try {
    return downloadDocumentAPI(userId);
  } catch (error: any) {
    console.log(error);
  }
};

export const updateCandidateStatus = createAsyncThunk(
  'interviewsData/updateCandidateStatus',
  async (payload: CandidateStatus, { rejectWithValue }) => {
    try {
      const interviewId = payload.interviewId;
      delete payload.interviewId;
      const response = await updateCandidateStatusAPI(payload, interviewId);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const interviewsSlice = createSlice({
  name: 'interviewsData',
  initialState,
  reducers: {
    setInterviewDetailsEmpty: (state) => {
      state.interviewDetails = null;
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
      if (!action.payload) {
        state.successMessage = '';
      }
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInterviewData.pending, (state) => {})
      .addCase(getInterviewData.fulfilled, (state, action) => {
        state.interviewsList = action.payload.data;
      })
      .addCase(getInterviewData.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(interviewAcceptanceStatusUpdate.pending, (state) => {})
      .addCase(interviewAcceptanceStatusUpdate.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;
        state?.interviewsList?.records.map((interview, index) => {
          if (interview.id === action?.payload?.data?.interviewId) {
            if (ROLES.CANDIDATE === action?.payload?.data?.role) {
              interview.candidateAcceptanceStatus =
                action?.payload?.data?.status;
            } else if (ROLES.INTERVIEWER === action?.payload?.data?.role) {
              interview.interviewerAcceptanceStatus =
                action?.payload?.data?.status;
            }
            if (
              interview.candidateAcceptanceStatus ===
                INTERVIEW_ACCEPTANCE_STATUS.CONFIRMED &&
              interview.interviewerAcceptanceStatus ===
                INTERVIEW_ACCEPTANCE_STATUS.CONFIRMED
            ) {
              state.interviewsList.records.splice(index, 1);
            }
          }
          return interview;
        });
      })
      .addCase(
        interviewAcceptanceStatusUpdate.rejected,
        (state, action: any) => {
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(deleteInterview.pending, (state) => {})
      .addCase(deleteInterview.fulfilled, (state, action) => {
        const { message, data } = action?.payload || {};
        state.isSuccess = true;
        state.successMessage = message;
        state.interviewsList.records = state.interviewsList.records.filter(
          (interview) => interview?.id !== data?.id
        );
      })
      .addCase(deleteInterview.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateCandidateStatus.pending, (state) => {})
      .addCase(updateCandidateStatus.fulfilled, (state, action) => {
        const updateInterviewStatus = (interviewsList: InterviewData[], interviewId: string, candidateStatus: string) => {
          return interviewsList.map((interview: InterviewData) => {
            if (interviewId === interview.id) {
              return { ...interview, candidateStatus };
            }
            return interview;
          });
        };
      
        const { interviewId, candidateStatus } = action.payload?.data || {};
      
        if (state.interviewDetails) {
          state.interviewDetails.candidateStatus = candidateStatus;
      
          if (state.interviewDetails.interviewId === interviewId) {
            state.interviewsList.records = updateInterviewStatus(state.interviewsList.records, interviewId, candidateStatus);
          }
        } else {
          state.interviewsList.records = updateInterviewStatus(state.interviewsList.records, interviewId, candidateStatus);
        }
      })
      .addCase(updateCandidateStatus.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getInterviewDetails.pending, (state) => {})
      .addCase(getInterviewDetails.fulfilled, (state, action) => {
        state.interviewDetails = action?.payload?.data;
      })
      .addCase(getInterviewDetails.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateInterviewDetails.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;
      })
      .addCase(updateInterviewDetails.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const { setInterviewDetailsEmpty, setIsError, setIsSuccess } =
  interviewsSlice.actions;
export default interviewsSlice.reducer;
