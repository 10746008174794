import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { InterviewDetailsType } from '../models';
import {
  getInterviewCallDetailsApi,
  saveInterviewCallNotesAPI
} from '../apis/InterviewCallAPI';

export interface InterviewCallState {
  interviewCallDetails: InterviewDetailsType;
  loadingInteviewCallData: boolean;
  isError: boolean;
  isSuccess: boolean;
  successMessage: string;
  errorMessage: string;
}

const initialState: InterviewCallState = {
  interviewCallDetails: null,
  loadingInteviewCallData: false,
  isError: false,
  isSuccess: false,
  successMessage: '',
  errorMessage: ''
};

export const selectorInterviewCallState = ({
  interviewCallData
}: RootState) => ({
  interviewCallDetails: interviewCallData.interviewCallDetails,
  loadingInteviewCallData: interviewCallData.loadingInteviewCallData,
  isError: interviewCallData.isError,
  isSuccess: interviewCallData.isSuccess,
  errorMessage: interviewCallData.errorMessage
});

export const getInterviewCallDetails = createAsyncThunk(
  'InterviewCallData/getInterviewDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getInterviewCallDetailsApi(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveInterviewCallNotes = createAsyncThunk(
  'InterviewCallData/saveInterviewNotes',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveInterviewCallNotesAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const interviewCallSlice = createSlice({
  name: 'interviewCallData',
  initialState,
  reducers: {
    setIsError: (state: InterviewCallState, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setSuccessMessageEmpty: (state: InterviewCallState, action) => {
      state.isSuccess = action.payload;
      if (!action.payload) {
        state.successMessage = '';
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInterviewCallDetails.pending, (state: InterviewCallState) => {
        state.loadingInteviewCallData = true;
      })
      .addCase(
        getInterviewCallDetails.fulfilled,
        (state: InterviewCallState, action) => {
          state.loadingInteviewCallData = false;
          if (action.payload) {
            let interviewData = action.payload.data;
            state.interviewCallDetails = interviewData;
          }
        }
      )
      .addCase(
        getInterviewCallDetails.rejected,
        (state: InterviewCallState, action: any) => {
          state.interviewCallDetails = null;
          state.loadingInteviewCallData = false;
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(saveInterviewCallNotes.pending, (state) => {})
      .addCase(saveInterviewCallNotes.fulfilled, (state: any, action) => {
        state.successMessage = action.payload.message;
      })
      .addCase(saveInterviewCallNotes.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const { setIsError, setSuccessMessageEmpty } =
  interviewCallSlice.actions;

export default interviewCallSlice.reducer;
