import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserData, DecodedToken, loginData } from '../models/login';
import {
  AUTH_CONFIG,
  ROLES,
  USER_TYPE
} from '../../../shared/constants/constants';
import jwt_decode from 'jwt-decode';
import { loginAPI, loginWithOTP, logOutAPI } from '../apis/login';
import { OrganizationData } from 'src/modules/Organization/models';
import { RootState } from 'src/store/reducer';
import {
  setAccessToken,
  setDecodedToken,
  setRefreshToken
} from 'src/shared/utils/storage';

const initialState = {
  // used in v3
  loginDomain: '',
  loginUserType: '',
  userData: <UserData>{},
  authToken: '',
  loginUserRole: '',
  userRoleMap: {},
  loading: false,
  error: false,
  errorObj: <any>{},
  errorOtpObj: <any>{},
  decodedToken: <DecodedToken>{},
  isLoginFailed: false,
  selectedOrganization: <OrganizationData>{},
  loggedInUserData: <loginData>{}
};

export const selectUserState = ({ user }: RootState) => ({
  loggedInUserData: user?.loggedInUserData,
  userData: user?.userData,
  loginUserType: user?.loginUserType,
  authToken: user?.authToken,
  loginUserRole: user?.loginUserRole,
  loading: user?.loading,
  error: user?.error,
  errorObj: user?.errorObj,
  errorOtpObj: user?.errorOtpObj,
  loginDomain: user?.loginDomain,
  decodedToken: user?.decodedToken,
  isLoginFailed: user?.isLoginFailed,
  selectedOrganization: user?.selectedOrganization
});

export const getLoginDetails = createAsyncThunk(
  'user/getLoginDetails',
  async (userData: object, { rejectWithValue }) => {
    try {
      return await loginAPI(userData);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyUserWithOTP = createAsyncThunk(
  'user/verifyUserWithOTP',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await loginWithOTP(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLogOutUser = createAsyncThunk(
  'user/getLogout',
  async (userData: object, { rejectWithValue }) => {
    try {
      return await logOutAPI(userData);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setData: (state, action) => {
      state.userData = action.payload;
    },
    setUserRole: (state, action) => {
      state.loginUserRole = action.payload;
    },
    resetData: (state) => {
      state.userData = <UserData>{};
      state.loginUserRole = '';
      setAuthToken('');
    },
    setIsErrorUpdated: (state, action) => {
      state.isLoginFailed = action.payload;
      state.errorObj = '';
      state.error = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setselectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setUserAndAccessTokenData: (state, action) => {
      state.userData = action.payload.userData;
      state.authToken = action.payload.token;
    },
    setLoginProvider: (state, action) => {
      state.loginDomain = action.payload;
    },
    setLoginData: (state, action) => {
      state.loggedInUserData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUserWithOTP.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorOtpObj = {};
        state.isLoginFailed = false;
        state.userData = <UserData>{};
      })
      .addCase(verifyUserWithOTP.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.userData = action.payload;
        state.authToken = action.payload.access;
        setAccessToken(action.payload.token);
        const decodedToken: DecodedToken = jwt_decode(action.payload.token);
        state.decodedToken = decodedToken;
        setDecodedToken(decodedToken);
        setRefreshToken(action.payload.refreshToken);
        if (state.decodedToken.role === ROLES.ADMIN_OPERATOR) {
          state.loginUserRole = ROLES.ADMIN_OPERATOR;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role === ROLES.SUPER_ADMIN) {
          state.loginUserRole = ROLES.SUPER_ADMIN;
          state.loginUserType = AUTH_CONFIG.LOGIN_TYPE_ADMIN;
        } else if (state.decodedToken.type === AUTH_CONFIG.LOGIN_TYPE_USER) {
          state.loginUserType = AUTH_CONFIG.LOGIN_TYPE_USER;
          if (state.decodedToken.role) {
            state.loginUserRole = state.decodedToken.role;
          }
        }
        state.isLoginFailed = false;
      })
      .addCase(verifyUserWithOTP.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.error = true;
        state.errorOtpObj.message = action.payload?.message;
      })

      .addCase(getLoginDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorObj = {};
        state.isLoginFailed = false;
        state.userData = <UserData>{};
      })
      .addCase(getLoginDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.userData = action.payload;
        state.authToken = action.payload.access;
        setAccessToken(action.payload.token);
        const decodedToken: DecodedToken = jwt_decode(action.payload.token);
        state.decodedToken = decodedToken;
        setDecodedToken(decodedToken);
        setRefreshToken(action.payload.refreshToken);
        if (state.decodedToken.role === ROLES.ADMIN_OPERATOR) {
          state.loginUserRole = ROLES.ADMIN_OPERATOR;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role === ROLES.SUPER_ADMIN) {
          state.loginUserRole = ROLES.SUPER_ADMIN;
          state.loginUserType = AUTH_CONFIG.LOGIN_TYPE_ADMIN;
        } else if (state.decodedToken.type === AUTH_CONFIG.LOGIN_TYPE_USER) {
          state.loginUserType = AUTH_CONFIG.LOGIN_TYPE_USER;
          if (state.decodedToken.role) {
            state.loginUserRole = state.decodedToken.role;
          }
        }
        state.isLoginFailed = false;
      })
      .addCase(getLoginDetails.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.error = true;
        let errorObj = { ...action?.payload };
        errorObj.message =
          'We regret to inform you that the credentials you provided are not valid. Please double-check your credentials and try again';
        state.errorObj = errorObj;
        resetData();
      })

      .addCase(getLogOutUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorObj = {};
        state.isLoginFailed = false;
      })
      .addCase(getLogOutUser.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userData = null;
          state.authToken = null;
          state.decodedToken = null;
          state.loginUserRole = null;
          state.isLoginFailed = false;
          setAccessToken(null);
        } else {
          state.isLoginFailed = false;
          state.error = false;
          resetData();
        }
      })
      .addCase(getLogOutUser.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.error = true;
        state.errorObj.message = action.payload?.message;
        resetData();
      });
  }
});

export const {
  setData,
  resetData,
  setAuthToken,
  setIsErrorUpdated,
  setselectedOrganization,
  setUserAndAccessTokenData,
  setLoginProvider,
  clearResults,
  setUserRole,
  setLoginData
} = userSlice.actions;

export default userSlice.reducer;
