import {
  apiDelete,
  apiGet,
  apiPost,
  ApiResponse
} from 'src/shared/utils/api-request';
import { AvailabilityData } from '../models';

export const saveAvailabilityAPI = async (
  payload: AvailabilityData
): Promise<ApiResponse<AvailabilityData>> => {
  const response = await apiPost(`/availability`, payload);
  return response.data;
};

export type SaveDayAvailabilityType = {
  weekStartDate: string;
  weekEndDate: string;
  eventData: AvailabilityData;
};
export const saveDayAvailabilityAPI = async (
  payload: SaveDayAvailabilityType
): Promise<ApiResponse<AvailabilityData[]>> => {
  const { weekStartDate, weekEndDate, eventData } = payload;
  const response = await apiPost(
    `/availability/day?startDate=${weekStartDate}&endDate=${weekEndDate}`,
    eventData
  );
  return response.data;
};

export type GetAvailabilityType = Pick<
  AvailabilityData,
  'userId' | 'start' | 'end' | 'isRecuring'
>;
export const getAvailabilityAPI = async ({
  userId,
  start,
  end,
  isRecuring
}: GetAvailabilityType): Promise<ApiResponse<AvailabilityData[]>> => {
  let url = `/availability/${userId}?`;
  if (start && end) {
    url += `startDate='${start}'&endDate='${end}'`;
  }
  if (isRecuring) {
    url += `&isRecuring=${isRecuring}`;
  }
  const response = await apiGet(url);
  return response.data;
};

export const deleteAvailabilityAPI = async (
  availabilityId: string
): Promise<ApiResponse<{ id: string }>> => {
  const response = await apiDelete(`/availability/${availabilityId}`);
  return response.data;
};

export type DelDayAvailabilityType = {
  availabilityId: string;
  weekStartDate: string;
  weekEndDate: string;
  userId: string;
};
export const deleteDayAvailabilityAPI = async (
  payload: DelDayAvailabilityType
): Promise<ApiResponse<AvailabilityData[]>> => {
  const response = await apiDelete(
    `/availability/${payload.availabilityId}/day`,
    payload
  );
  return response.data;
};
