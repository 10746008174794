import { Box, FormControlLabel } from '@mui/material';
import moment from 'moment';
import { CheckBox } from '../checkbox/CheckBox';
import { AvailabilityData } from 'src/modules/ManageSlots/models';

const CustomWeekHeader = ({
  date,
  displayDate,
  handleStatusClick,
  weekHeaderData,
  view,
  ...props
}) => {
  let dayNum = date.getDate();
  let todayDate = moment().format('D/MM/YYYY');
  let isCurrent = todayDate === moment(date).format('D/MM/YYYY') ? true : false;
  const weekCurrentDate = isCurrent ? 'weekHeaderClassCurrentDate' : '';
  const hide = !displayDate ? 'displayNone' : '';

  const isSelected = (): boolean => {
    let dayName = moment(date).format('ddd').toLowerCase();
    let elements = document.getElementsByClassName(`fc-day-${dayName}`);
    if (
      weekHeaderData &&
      weekHeaderData.find((dat: AvailabilityData) =>
        moment(date).isSame(dat.start, 'date')
      )
    ) {
      Array.from(elements).map((el) => {
        el.classList.add('selectedCol');
      });
      return false;
    } else {
      Array.from(elements).map((el) => {
        el.classList.remove('selectedCol');
      });
      return true;
    }
  };

  return (
    <Box className={'customWeekHeader'}>
      <FormControlLabel
        control={
          <CheckBox
            checked={isSelected()}
            onChange={(event, checked) =>
              handleStatusClick(
                checked,
                date,
                view.currentStart,
                view.currentEnd
              )
            }
          />
        }
        label={'Available'}
        className={hide}
      />
      <div className={`headerDayLabel mb-8 ${!displayDate && 'mt-30'} `}>
        {moment(date).format('ddd')}
      </div>
      <div className={`headerDayLabelVal ${weekCurrentDate} ${hide}`}>
        {dayNum}
      </div>
    </Box>
  );
};

export default CustomWeekHeader;
