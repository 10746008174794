import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { CustomFormType } from './service/customFormInterface';
import { Grid } from '@mui/material';
import FormField from '../form-field/FormField';
import { Button } from '../button/Button';
import Card from '../card/Card';
import Typography from '../typography/Typography';

export const CustomForm = ({
  formFields,
  initialValues,
  submitBtnText = 'Submit',
  cancelBtnText = 'Cancel',
  continueBtnText = 'Continue',
  editFieldValues,
  showCancelBtn = true,
  showContinueButton = false,
  showSubmitBtn = true,
  submitBtnHandler,
  cancelBtnHandler,
  continueButtonHandler,
  continueButtonVariant = 'outlined',
  enableReinitialize = false,
  title,
  isFileDeleted = false,
  showCard = true,
  isValidPropHandler
}: CustomFormType) => {
  //Form constants
  const formik = useFormik({
    initialValues,
    enableReinitialize,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values: any) => {
      submitBtnHandler(values);
      handleReset();
    }
  });
  const { isValid, handleSubmit, resetForm, validateForm } = formik;

  //useEffect

  useEffect(() => {
    if (isValidPropHandler) {
      isValidPropHandler(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (editFieldValues && Object.keys(editFieldValues)?.length) {
      formik.setValues(editFieldValues);
    }
  }, [editFieldValues]);

  //Methods
  const handleReset = async () => {
    await resetForm();
    validateForm();
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form>
          {showCard ? (
            <Card sx={{ mx: 6, mt: 4, px: 6, py: 5 }}>
              {title && (
                <Typography variant="h4" sx={{ mb: 3 }}>
                  {title}
                </Typography>
              )}
              <Grid container spacing={4} direction="row">
                {formFields?.map((field, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={field.gridColumns}
                    lg={field.gridColumns}
                    xl={field.gridColumns}
                  >
                    <FormField fieldProps={field} />
                  </Grid>
                ))}
              </Grid>
            </Card>
          ) : (
            <>
              {title && (
                <Typography variant="h4" sx={{ mb: 3 }}>
                  {title}
                </Typography>
              )}
              <Grid container spacing={4} direction="row">
                {formFields?.map((field, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={field.gridColumns}
                    lg={field.gridColumns}
                    xl={field.gridColumns}
                  >
                    <FormField fieldProps={field} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Grid
            sx={{ mt: 1, ml: -5 }}
            container
            direction={'row-reverse'}
            spacing={2}
          >
            {showSubmitBtn && (
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                lg={2}
                xl={2}
                sx={{ marginLeft: 1 }}
              >
                <Button
                  btnText={submitBtnText}
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!isValid || isFileDeleted}
                  onClick={(event: any) => handleSubmit(event)}
                />
              </Grid>
            )}

            {showCancelBtn && (
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <Button
                  fullWidth
                  btnText={cancelBtnText}
                  variant="outlined"
                  onClick={cancelBtnHandler}
                />
              </Grid>
            )}
            {showContinueButton && (
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <Button
                  fullWidth
                  btnText={continueBtnText}
                  variant={continueButtonVariant}
                  onClick={continueButtonHandler}
                  disabled={!isValid}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
};

export default CustomForm;
