import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../../core/interceptors/axios-instance';
import { SERVER_API_URL } from '../constants/constants';
import {
  getAccessToken,
  getDecodedToken,
  setAccessToken,
  setDecodedToken,
  setRefreshToken
} from './storage';
import { selectUserState } from '../../auth/login/services/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DecodedToken } from 'src/auth/login/models/login';
import jwt_decode from 'jwt-decode';

export type ApiResponse<T> = {
  status: boolean;
  message: string;
  data: T;
};
export interface TAxiosCustomConfig {
  variables?: string;
  setLoading?: (res: true | false) => void;
}
export interface Config extends AxiosRequestConfig {
  custom?: TAxiosCustomConfig;
}

export async function apiGet<T = any>(
  resource: string,
  config?: AxiosRequestConfig
) {
  await validateToken(config);
  return axiosInstance.get<T>(`${resource}`, config);
}

export async function apiPost<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  await validateToken(config);
  return axiosInstance.post<T>(resource, data, config);
}

export async function apiPut<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  await validateToken(config);
  return axiosInstance.put<T>(resource, data, config);
}

export async function apiDelete<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  await validateToken(config);
  return axiosInstance.delete<T>(resource, { data: data });
}

export async function apiPatch<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  await validateToken(config);
  return axiosInstance.patch<T>(resource, data, config);
}

export async function validateToken(config?: AxiosRequestConfig) {
  const decodedTokenStr: string = getDecodedToken();
  if (decodedTokenStr) {
    const decodedToken: DecodedToken = JSON.parse(decodedTokenStr);
    if (decodedToken) {
      const date: Date = new Date(decodedToken?.exp * 1000);
      if (date <= new Date()) {
        const response = await axiosInstance.get('/auth/token/refresh', config);
        const data = response.data;
        setAccessToken(data.token);
        const decodedToken: DecodedToken = jwt_decode(data.token);
        setDecodedToken(decodedToken);
        setRefreshToken(data.refreshToken);
      }
    }
  }
}
