import { RootState } from 'src/store/reducer';
import { AvailabilityData, AvailabilityStatus } from '../models';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteAvailabilityAPI,
  GetAvailabilityType,
  getAvailabilityAPI,
  saveAvailabilityAPI,
  saveDayAvailabilityAPI,
  SaveDayAvailabilityType,
  DelDayAvailabilityType,
  deleteDayAvailabilityAPI
} from '../apis/ManageSlotsAPI';

export type ManageSlotsState = {
  availabilityList: AvailabilityData[];
  isError: boolean;
  errorMessage: string;
  isSuccess: boolean;
  successMessage: string;
};

const initialState: ManageSlotsState = {
  availabilityList: [],
  isError: false,
  errorMessage: '',
  isSuccess: false,
  successMessage: ''
};

export const saveAvailability = createAsyncThunk(
  'ManageSlotsData/saveAvailability',
  async (payload: AvailabilityData, { rejectWithValue }) => {
    try {
      const response = await saveAvailabilityAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveDayAvailability = createAsyncThunk(
  'ManageSlotsData/saveDayAvailability',
  async (payload: SaveDayAvailabilityType, { rejectWithValue }) => {
    try {
      const response = await saveDayAvailabilityAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAvailabilityList = createAsyncThunk(
  'ManageSlotsData/getAvailabilityList',
  async (payload: GetAvailabilityType, { rejectWithValue }) => {
    try {
      const response = await getAvailabilityAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAvailability = createAsyncThunk(
  'ManageSlotsData/deleteAvailability',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteAvailabilityAPI(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDayAvailability = createAsyncThunk(
  'ManageSlotsData/deleteDayAvailability',
  async (payload: DelDayAvailabilityType, { rejectWithValue }) => {
    try {
      const response = await deleteDayAvailabilityAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const manageSlotsSlice = createSlice({
  name: 'manageSlotsData',
  initialState,
  reducers: {
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
      if (!action.payload) {
        state.successMessage = '';
      }
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAvailability.pending, (state) => {})
      .addCase(saveAvailability.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;
        let savedEvent = action.payload.data;
        state.availabilityList = [...state.availabilityList, savedEvent];
      })
      .addCase(saveAvailability.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveDayAvailability.pending, (state) => {})
      .addCase(saveDayAvailability.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;
        state.availabilityList = action.payload.data;
      })
      .addCase(saveDayAvailability.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAvailabilityList.pending, (state) => {})
      .addCase(getAvailabilityList.fulfilled, (state, action) => {
        state.availabilityList = action.payload.data;
      })
      .addCase(getAvailabilityList.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAvailability.pending, (state) => {})
      .addCase(deleteAvailability.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.isSuccess = true;
          state.successMessage = action?.payload?.message;
          state.availabilityList = state.availabilityList.filter(
            (availability) => availability?.id !== action?.payload?.data?.id
          );
        }
      })
      .addCase(deleteAvailability.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteDayAvailability.pending, (state) => {})
      .addCase(deleteDayAvailability.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.isSuccess = true;
          state.successMessage = action?.payload?.message;
          state.availabilityList = action?.payload?.data;
        }
      })
      .addCase(deleteDayAvailability.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const selectManageSlotsState = ({ manageSlots }: RootState) => ({
  availabilityList: manageSlots?.availabilityList,
  isError: manageSlots?.isError,
  errorMessage: manageSlots?.errorMessage,
  isSuccess: manageSlots?.isSuccess,
  successMessage: manageSlots?.successMessage
});
export const { setIsError, setIsSuccess } = manageSlotsSlice.actions;
export default manageSlotsSlice.reducer;
