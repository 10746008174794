import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { FilterData, JobDetails } from '../models';
import { DocumentType } from '../../../shared/models';
import {
  deleteAssignedTagAPI,
  deleteJdRecordAPI,
  editJdRecordAPI,
  filterDataApi,
  getAssignTagsAPI,
  getJdListAPI,
  getUsersDataAPI,
  saveAssignTagsAPI,
  saveJobDetailsAPI,
  uploadFileAPI,
  attachJdToCandidateAPI,
  updateJdStatusAPI,
  savePickedCandidateAPI,
  getSubmittedCandidatesListAPI,
  downloadDocumentAPI,
  updateJdDocumentDetailsAPI,
  getInterviewerListByFilterAPI,
  scheduleInterviewAPI,
  checkResumeAttachAPI,
  selectResumeAndApplyToJDAPI,
  addCompanyMasterDataAPI,
  getPickCandidatesListAPI,
  getDocByDocId,
  saveAssignedTemplatesAPI
} from '../apis/JdAPI';
import { USER_ROLES } from 'src/shared/constants/constants';
import {
  Pagination,
  PaginationResponseDto,
  initalPaginationData
} from 'src/core/model/pagination.dto';
import { UserData } from 'src/modules/user-management/models';

export interface JdState {
  filterData: FilterData;
  isFilterDataUpdate: boolean;
  selectedJdData: JobDetails;
  pickCandidatesList: PaginationResponseDto<UserData>;
  submittedCandidateList: PaginationResponseDto<UserData>;
  interviewerList: [];
  candidateSelectedToSchedule: {};
  jdListData: PaginationResponseDto<JobDetails>;
  tagListData: PaginationResponseDto<UserData>;
  assignedTagsList: string[];
  loadingJdData: boolean;
  showApplyButton: boolean;
  isError: boolean;
  isSuccess: boolean;
  successMessage: string;
  errorMessage: string;
  uploadedCustomerLogo: DocumentType;
  uploadedAttachFile: DocumentType[];
  assignedTagProfiles: DocumentType[];
}

const initialState: JdState = {
  filterData: null,
  isFilterDataUpdate: false,
  selectedJdData: null,
  submittedCandidateList: null,
  pickCandidatesList: initalPaginationData,
  jdListData: initalPaginationData,
  interviewerList: [],
  candidateSelectedToSchedule: null,
  tagListData: initalPaginationData,
  assignedTagsList: [],
  loadingJdData: null,
  showApplyButton: false,
  isError: false,
  isSuccess: false,
  successMessage: '',
  errorMessage: '',
  uploadedCustomerLogo: null,
  uploadedAttachFile: null,
  assignedTagProfiles: []
};

export const selectorJdState = ({ jdData }: RootState) => ({
  filterData: jdData?.filterData,
  isFilterDataUpdate: jdData?.isFilterDataUpdate,
  selectedJdData: jdData?.selectedJdData,
  pickCandidatesList: jdData?.pickCandidatesList,
  submittedCandidateList: jdData?.submittedCandidateList,
  jdListData: jdData?.jdListData,
  showApplyButton: jdData?.showApplyButton,
  interviewerList: jdData?.interviewerList,
  candidateSelectedToSchedule: jdData?.candidateSelectedToSchedule,
  isError: jdData?.isError,
  isSuccess: jdData?.isSuccess,
  tagListData: jdData?.tagListData,
  assignedTagsList: jdData?.assignedTagsList,
  uploadedCustomerLogo: jdData?.uploadedCustomerLogo,
  uploadedAttachFile: jdData?.uploadedAttachFile,
  successMessage: jdData?.successMessage,
  errorMessage: jdData?.errorMessage,
  assignedTagProfiles: jdData?.assignedTagProfiles
});

export const getJdFilterData = createAsyncThunk(
  'JdData/getJdFilterData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await filterDataApi();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const attachJdToCandidate = createAsyncThunk(
  'JdData/attachJdToCandidate',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await attachJdToCandidateAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadAttachFile = createAsyncThunk(
  'JdData/uploadAttachFile',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addSkill = createAsyncThunk(
  'JdData/addSkill',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await addCompanyMasterDataAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadCustomerLogo = createAsyncThunk(
  'JdData/uploadCustomerLogo',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response: DocumentType[] = await uploadFileAPI(payload);
      const logoUrl = await downloadDocumentAPI(response[0]?.id);
      let logoData: DocumentType = { ...response[0], blobUrl: logoUrl };
      return logoData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveJobDetails = createAsyncThunk(
  'JdData/saveJobDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveJobDetailsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJdListData = createAsyncThunk(
  'JdData/getJdListData',
  async (
    payload: { paginationData: Pagination; filter?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getJdListAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editJdListData = createAsyncThunk(
  'JdData/editJdListData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editJdRecordAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateJdStatusData = createAsyncThunk(
  'JdData/updateJdStatusData',
  async (payload: { status: string; jdId: string }, { rejectWithValue }) => {
    try {
      const { jdId } = payload;
      delete payload.jdId;
      const response = await updateJdStatusAPI(payload, jdId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteJdRecord = createAsyncThunk(
  'JdData/deleteJdRecord',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await deleteJdRecordAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJdDocuments = createAsyncThunk(
  'JdData/getJdDocuments',
  async (
    payload: { profile: string; attachFileId: string },
    { rejectWithValue }
  ) => {
    try {
      const logoUrl = await downloadDocumentAPI(payload?.profile);
      const customerLogo: DocumentType = {
        id: payload?.profile,
        blobUrl: logoUrl
      };

      const attachFile = await getDocByDocId(payload.attachFileId);

      return {
        logo: customerLogo,
        attachFileJd: [attachFile.data]
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTagProfilePicture = createAsyncThunk(
  'JdData/getTagProfilePicture',
  async (
    payload: { profileId: string; userId: string },
    { rejectWithValue }
  ) => {
    try {
      const logoUrl = await downloadDocumentAPI(payload.profileId);
      const customerLogo: DocumentType = {
        id: payload.profileId,
        userId: payload.userId,
        blobUrl: logoUrl
      };
      return {
        logo: customerLogo
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateJdDocument = createAsyncThunk(
  'JdData/updateJdDocument',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateJdDocumentDetailsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCustomerLogo = createAsyncThunk(
  'JdData/updateCustomerLogo',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editJdRecordAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTagsList = createAsyncThunk(
  'JdData/getAllTagsList',
  async (payload: Pagination, { rejectWithValue }) => {
    try {
      const response = await getUsersDataAPI(payload, USER_ROLES.tag);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveAssignTags = createAsyncThunk(
  'JdData/saveAssignTags',
  async (payload: { jdId: string; userIds: string[] }, { rejectWithValue }) => {
    try {
      const jdId = payload.jdId;
      delete payload.jdId;
      const response = await saveAssignTagsAPI(payload, jdId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAssignedTags = createAsyncThunk(
  'JdData/getAssignedTags',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await getAssignTagsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAssignedTag = createAsyncThunk(
  'JdData/deleteAssignedTag',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await deleteAssignedTagAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubmittedCandidatesList = createAsyncThunk(
  'JdData/getSubmittedCandidatesList',
  async (
    {
      initialPagination,
      selectedJdId
    }: { initialPagination: Pagination; selectedJdId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getSubmittedCandidatesListAPI(
        initialPagination,
        selectedJdId
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCandidatesList = createAsyncThunk(
  'JdData/getCandidatesList',
  async (
    {
      initialPagination,
      selectedJdId
    }: { initialPagination: Pagination; selectedJdId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getPickCandidatesListAPI(
        initialPagination,
        selectedJdId
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const savePickedCandidate = createAsyncThunk(
  'JdData/savePickedCandidate',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await savePickedCandidateAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewerListByFilter = createAsyncThunk(
  'JdData/getInterviewerListByFilter',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getInterviewerListByFilterAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const scheduleInterview = createAsyncThunk(
  'JdData/scheduleInterview',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await scheduleInterviewAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkResumeAttach = createAsyncThunk(
  'JdData/checkResumeAttach',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await checkResumeAttachAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectResumeAndApplyToJD = createAsyncThunk(
  'JdData/selectResumeAndApplyToJD',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await selectResumeAndApplyToJDAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveAssignedTemplates = createAsyncThunk(
  'JdData/saveAssignedTemplates',
  async (
    payload: { assignedTemplateIds: string[]; jdId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await saveAssignedTemplatesAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const jdSlice = createSlice({
  name: 'jdData',
  initialState,
  reducers: {
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setSuccessMessageEmpty: (state: any, action) => {
      state.isSuccess = action.payload;
      if (!action.payload) {
        state.successMessage = '';
      }
    },
    setSelectedJd: (state: JdState, action) => {
      state.selectedJdData = action.payload;
    },
    setIsFilterDataUpdate: (state: JdState, action) => {
      state.isFilterDataUpdate = action.payload;
    },
    seInterviewerListEmpty: (state) => {
      state.interviewerList = null;
    },
    setCandidateSelectedToSchedule: (state, action) => {
      state.candidateSelectedToSchedule = action?.payload;
    },
    setSelectedJdEmpty: (state: any, action) => {
      if (!action.payload) {
        state.selectedJdData = null;
        state.uploadedAttachFile = [];
        state.uploadedCustomerLogo = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJdFilterData.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(getJdFilterData.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          let data = action.payload.data;
          state.filterData = data;
        }
      })
      .addCase(getJdFilterData.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addSkill.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(addSkill.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.isFilterDataUpdate = true;
      })
      .addCase(addSkill.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadCustomerLogo.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(uploadCustomerLogo.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          state.uploadedCustomerLogo = action.payload;
        }
      })
      .addCase(uploadCustomerLogo.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadAttachFile.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(uploadAttachFile.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          state.uploadedAttachFile = action.payload;
        }
      })
      .addCase(uploadAttachFile.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveJobDetails.pending, (state) => {
        state.loadingJdData = false;
      })
      .addCase(saveJobDetails.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.selectedJdData = action.payload.data;
        state.successMessage = action.payload.message;
      })
      .addCase(saveJobDetails.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getJdListData.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(getJdListData.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          let jdData = action.payload.data;
          state.jdListData = jdData;
        }
      })
      .addCase(getJdListData.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editJdListData.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(editJdListData.fulfilled, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(editJdListData.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteJdRecord.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(deleteJdRecord.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(deleteJdRecord.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getJdDocuments.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(getJdDocuments.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        let customerLogo: DocumentType = state.uploadedCustomerLogo;
        let returnCustomerLogo = action.payload.logo;
        state.uploadedCustomerLogo = returnCustomerLogo;
        state.uploadedAttachFile = action.payload.attachFileJd;
      })
      .addCase(getJdDocuments.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getTagProfilePicture.pending, (state) => {
        state.loadingJdData = true;
      })

      .addCase(getTagProfilePicture.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.assignedTagProfiles = [
          ...state.assignedTagProfiles,
          action.payload.logo
        ];
      })

      .addCase(getTagProfilePicture.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAllTagsList.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(getAllTagsList.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          let tagsData = action.payload;
          state.tagListData = tagsData;
        }
      })
      .addCase(getAllTagsList.rejected, (state: any, action: any) => {
        state.loadingJdData = false;
        state.tagListData = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveAssignTags.pending, (state) => {
        state.loadingJdData = false;
      })
      .addCase(saveAssignTags.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        // state.assignedTagsList = action.payload.data;
        state.successMessage = action.payload.message;
      })
      .addCase(saveAssignTags.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAssignedTags.pending, (state) => {
        state.loadingJdData = true;
        state.assignedTagProfiles = [];
      })
      .addCase(getAssignedTags.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          let tagsData = action.payload.data;
          state.assignedTagsList = tagsData;
        }
      })
      .addCase(getAssignedTags.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.assignedTagsList = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getSubmittedCandidatesList.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(
        getSubmittedCandidatesList.fulfilled,
        (state: JdState, action) => {
          state.loadingJdData = false;
          if (action.payload) {
            state.submittedCandidateList = action?.payload?.data;
          }
        }
      )
      .addCase(
        getSubmittedCandidatesList.rejected,
        (state: JdState, action: any) => {
          state.loadingJdData = false;
          state.assignedTagsList = [];
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(attachJdToCandidate.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(attachJdToCandidate.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;
      })
      .addCase(attachJdToCandidate.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getInterviewerListByFilter.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(
        getInterviewerListByFilter.fulfilled,
        (state: JdState, action) => {
          state.loadingJdData = false;
          if (action.payload) {
            state.interviewerList = action?.payload?.data;
          }
        }
      )
      .addCase(
        getInterviewerListByFilter.rejected,
        (state: JdState, action: any) => {
          state.loadingJdData = false;
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(scheduleInterview.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(scheduleInterview.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          state.isSuccess = true;
          state.successMessage = action?.payload?.message;
        }
      })
      .addCase(scheduleInterview.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getCandidatesList.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(getCandidatesList.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          state.pickCandidatesList = action?.payload;
        }
      })
      .addCase(getCandidatesList.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.assignedTagsList = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(savePickedCandidate.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(savePickedCandidate.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        if (action.payload) {
          state.isSuccess = true;
          state.successMessage = action?.payload?.message;

          // update pick candidate list
          state.pickCandidatesList.records =
            state.pickCandidatesList?.records?.map((candidate) => {
              if (candidate.id === action.payload.data.id) {
                candidate.userJdStatus = 'SHORTLISTED';
              }
              return candidate;
            });
        }
      })
      .addCase(savePickedCandidate.rejected, (state: JdState, action: any) => {
        state.loadingJdData = false;
        state.assignedTagsList = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAssignedTag.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(deleteAssignedTag.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(deleteAssignedTag.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateCustomerLogo.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(updateCustomerLogo.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        const selectedJdData: JobDetails = state.selectedJdData;
        selectedJdData.profile = action.payload.id;
      })
      .addCase(updateCustomerLogo.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(checkResumeAttach.pending, (state) => {
        state.loadingJdData = true;
      })
      .addCase(checkResumeAttach.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.showApplyButton = action?.payload?.data?.isResumeAttach;
      })
      .addCase(checkResumeAttach.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(selectResumeAndApplyToJD.pending, (state) => {
        state.loadingJdData = true;
        state.showApplyButton = false;
      })
      .addCase(selectResumeAndApplyToJD.fulfilled, (state: JdState, action) => {
        state.loadingJdData = false;
        state.showApplyButton = action?.payload?.data?.isResumeAttach;
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;
      })
      .addCase(selectResumeAndApplyToJD.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveAssignedTemplates.pending, (state) => {})
      .addCase(saveAssignedTemplates.fulfilled, (state: JdState, action) => {
        state.isSuccess = true;
        state.successMessage = action?.payload?.message;

        console.log(action.payload);
      })
      .addCase(saveAssignedTemplates.rejected, (state, action: any) => {
        state.loadingJdData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const {
  setIsError,
  setSuccessMessageEmpty,
  setSelectedJd,
  seInterviewerListEmpty,
  setCandidateSelectedToSchedule,
  setSelectedJdEmpty,
  setIsFilterDataUpdate
} = jdSlice.actions;

export default jdSlice.reducer;
