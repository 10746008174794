import { Pagination } from 'src/core/model/pagination.dto';
import {
  Config,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';
import { generateDocumentUrl } from 'src/shared/utils/utils';

export const findAllCompaniesAPI = async (payload) => {
  const res = await apiGet(
    `/company?limit=${payload.limit}&page=${payload.page}${getSearchText(
      payload
    )}`
  );
  return res.data;
};

export const getTenantByTenantUserAPI = async (userId: string) => {
  const res = await apiGet(`/tenant-user/${userId}/tenant`);
  return res.data;
};

// Add New Organization.
export const addCompanyAPI = async (payload: any) => {
  const res = await apiPost('/company', payload);
  return res.data;
};

export const editCompanyAPI = async (payload: any, id: string) => {
  const res = await apiPut(`/company/${id}`, payload);
  return res.data;
};

export const resendInviteCompanyAPI = async (
  payload: any,
  companyId: string
) => {
  const res = await apiPost(`/company/${companyId}/resend-invite`, payload);
  return res.data;
};

export const toggleCompanyStatusAPI = async (companyId: string) => {
  const res = await apiPatch(`/company/${companyId}/status`, {});
  return res.data;
};

export const uploadDocumentAPI = async (formData: FormData) => {
  const res = await apiPost(`document/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const updateProfilePicAPI = async (payload) => {
  const res = await apiPatch(`/company/${payload.companyId}/logo`, payload);
  return res.data;
};

export const downloadDocumentAPI = async (documentId: string) => {
  const res = await apiGet(`document/download/${documentId}`, {
    responseType: 'blob'
  });
  return generateDocumentUrl(res);
};

export function getSearchText(payload: Pagination) {
  if (!payload || !payload.searchText) {
    return '';
  }
  return `&search=${payload.searchText}`;
}
