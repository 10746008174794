import React, { useState, MouseEvent, useEffect, RefObject } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ButtonToggle } from 'src/shared/components/index';
import { customInternationDate2 } from 'src/shared/utils/utils';
import { SlotInfo, ToolbarProps } from 'react-big-calendar';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import { I18N } from 'src/shared/constants/constants';
import { useTranslation } from 'react-i18next';

interface CalenderHeaderTypeProp {
  view?: any;
  handleViewOrientation?: (event: MouseEvent<HTMLElement>, value: any) => void;
  calendarRef: RefObject<FullCalendar>;
  isControls: boolean;
  title: string;
  displayLegends: boolean;
}

const CalenderHeader = ({
  calendarRef,
  view,
  handleViewOrientation,
  isControls,
  title,
  displayLegends
}: CalenderHeaderTypeProp) => {
  const [updatedLabel, setLabel] = useState<string>(
    getWeekFormatHeader(Date())
  );

  const { t } = useTranslation(I18N.ALL);

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      const weekLabel = getWeekFormatHeader(calApi.getDate());
      setLabel(weekLabel);
    }
  }, [calendarRef]);

  const navigate = (direction: 'PREV' | 'NEXT') => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      if (direction === 'PREV') {
        calApi.prev();
      } else if (direction === 'NEXT') {
        calApi.next();
      } else {
        calApi.today();
      }
    }
  };

  return (
    <Box className="calenderHeaderBar flex-basic-space-between p-relative">
      <Box className="flex-basic-center" gap={1}>
        <Box className="headerTitle">{title}</Box>
        <Tooltip
          placement="bottom"
          title={t('manage-slots.manageYourAvailibility')}
        >
          <InfoOutlinedIcon></InfoOutlinedIcon>
        </Tooltip>
      </Box>
      <Box
        className={`d-flex nextPrevControls ${!isControls && 'displayNone'}`}
      >
        <Box
          className="btnBox flex-basic-center cursorPointer"
          onClick={() => {
            navigate('PREV');
          }}
          sx={{ mr: 3 }}
        >
          <ChevronLeftIcon></ChevronLeftIcon>
        </Box>
        <Box className="monthLabel">{updatedLabel}</Box>
        <Box
          className="btnBox flex-basic-center cursorPointer"
          onClick={() => {
            navigate('NEXT');
          }}
          sx={{ ml: 3 }}
        >
          <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
        </Box>
      </Box>
      <Box
        className="headerLegends"
        sx={{ visibility: displayLegends ? 'visible' : 'hidden' }}
      >
        <Box className="flex-basic-center">
          <Box className="mr-4 legendIcon"></Box>
          <Box>Accepted</Box>
        </Box>
        <Box className="flex-basic-center">
          <Box className="mr-4 legendIcon"></Box>
          <Box>Not Accepted Yet</Box>
        </Box>
      </Box>
      <Box
        className="cal-view-tabs p-absolute displayNone"
        sx={{ right: '18px' }}
      >
        <ButtonToggle
          buttons={[
            {
              content: 'Day',
              value: 'day'
            },
            {
              content: 'Week',
              value: 'week'
            },
            {
              content: 'Month',
              value: 'month'
            }
          ]}
          value={view}
          size="large"
          exclusive
          onChange={handleViewOrientation}
        />
      </Box>
    </Box>
  );
};

export default CalenderHeader;

const getWeekFormatHeader = (date: Date | string): string => {
  let currentDate = moment(date);
  let weekStart = currentDate.clone().startOf('week').format('Do MMMM');
  let weekEnd = currentDate.clone().endOf('week').format('Do MMMM');
  return `${weekStart} - ${weekEnd}`;
};
