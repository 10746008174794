import { Container } from '@mui/material';
//import logo from '../../../assets/images/TekCheck.svg';
import logo from '../../../assets/images/indexnine.svg';
import { useNavigate } from 'react-router';
import * as ROUTES from '../../../shared/constants/routes';
import { useSelector } from 'react-redux';
import { selectUserState } from 'src/auth/login/services/userSlice';
import { ROLES } from 'src/shared/constants/constants';

export const Logo = () => {
  const navigate = useNavigate();
  const { loginUserRole } = useSelector(selectUserState);

  return (
    <>
      <Container>
        <img
          height={'42px'}
          className={`logoIMG ${
            loginUserRole === ROLES.SUPER_ADMIN ||
            loginUserRole === ROLES.TENANT_ADMIN
              ? 'cursorPointer'
              : ''
          }`}
          src={logo}
          alt="Logo"
          onClick={() =>
            loginUserRole === ROLES.SUPER_ADMIN ||
            loginUserRole === ROLES.TENANT_ADMIN
              ? navigate(ROUTES.DASHBOARD)
              : ''
          }
        />
      </Container>
    </>
  );
};
