import { ReactNode, useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { FormFieldType } from './service/formFieldInterface';
import { FIELD_TYPE, I18N, REGEX } from 'src/shared/constants/constants';
import {
  CheckBox,
  Radio,
  Select,
  TextField,
  Autocomplete,
  CheckboxGroup,
  RadioGroup,
  FormLabel,
  FormControl,
  DatePicker,
  FileButton
} from '../index';
import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Switch
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import TextEditorWrapper from '../text-editor/TextEditorWrapper';
import {
  AutocompleteRenderInputParams,
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Skill } from 'src/shared/models';
import AutocompleteTagsCreate from '../autocomplete/AutocompleteTagsCreate';
const filter = createFilterOptions<Skill>();

interface FormFieldProps {
  fieldProps: FormFieldType;
}

export const FormField = ({ fieldProps }: FormFieldProps) => {
  //Constants
  const { t } = useTranslation([I18N.DEFAULT]);
  //state
  const [value, setValue] = useState(
    fieldProps?.type === FIELD_TYPE.MULTI_SELECT ? ([] as any[]) : ({} as any)
  );
  const [showPassword, setShowPassword] = useState(false);

  const handleOnSkillChange = (event, field, value, form, reason, details) => {
    if (details.option.create && reason !== 'removeOption') {
      fieldProps?.handleFieldChange(form, field, value, details);
      form.setFieldValue(field?.name, value);
    } else if (reason === 'removeOption') {
      fieldProps?.handleFieldChange(form, field, value, details);
      form.setFieldValue(field?.name, value);
    } else if (reason === 'selectOption') {
      fieldProps?.handleFieldChange(form, field, value, details);
      form.setFieldValue(field?.name, value);
    } else if (reason !== 'createOption') {
      fieldProps?.handleFieldChange(form, field, value, details);
      form.setFieldValue(field?.name, value);
    }
  };
  //methods
  const handleOnChange = (event, field, value?, form?) => {
    // Text only and number only validation checks
    if (
      fieldProps?.type === FIELD_TYPE.TEXT &&
      !REGEX.TEXT_ONLY.test(event?.target?.value)
    ) {
      return;
    } else if (
      fieldProps?.type === FIELD_TYPE.ALPHA_NUMBERICS &&
      !REGEX.ALPHA_NUMBERICS.test(event?.target?.value)
    ) {
      return;
    } else if (
      fieldProps?.type === FIELD_TYPE.INTEGER_ONLY &&
      !REGEX.NUMBER_INTEGER.test(event?.target?.value)
    ) {
      return;
    } else if (
      fieldProps?.type === FIELD_TYPE.DATE &&
      !REGEX.DATE.test(event?.target?.value)
    ) {
      if (fieldProps?.handleFieldChange) {
        form.setFieldValue(field?.name, value);
        fieldProps?.handleFieldChange(form, field, value);
      }
      return;
    } else if (fieldProps?.type === FIELD_TYPE.TEXT_EDITOR) {
      form.setFieldValue(field?.name, value);
      fieldProps?.handleFieldChange(form, fieldProps, value);
      return;
    }

    if (
      fieldProps?.type === FIELD_TYPE.AUTOCOMPLETE ||
      fieldProps?.type === FIELD_TYPE.SWITCH ||
      fieldProps?.type === FIELD_TYPE.MULTI_SELECT
    ) {
      //Parent's on change function calls
      setValue(value);
      form.setFieldValue(field?.name, value);
    }
    if (fieldProps?.type === FIELD_TYPE.CHECKBOX) {
      form.setFieldValue(field?.name, event?.target?.checked);
    }
    field.onChange(event);
    if (
      fieldProps?.handleFieldChange &&
      fieldProps?.type === FIELD_TYPE.CHECKBOX
    ) {
      fieldProps?.handleFieldChange(event, field, event?.target?.checked);
    } else if (fieldProps?.handleFieldChange) {
      fieldProps?.handleFieldChange(event, field, value);
    }
  };

  const validateField = (
    value: string,
    fieldType: string,
    isRequired: boolean,
    fieldLabel: string
  ) => {
    if (!value && isRequired) {
      return t('validationErrMsg.required_err_msg', { fieldLabel });
    } else if (
      fieldProps?.errorMessages?.maxLenghtErrMsg &&
      value?.length > fieldProps?.validations?.maxLength
    ) {
      return fieldProps?.errorMessages?.maxLenghtErrMsg;
    } else if (
      fieldProps?.errorMessages?.minLengthErrMsg &&
      value?.length < fieldProps?.validations?.minLength
    ) {
      return fieldProps?.errorMessages?.minLengthErrMsg;
    } else if (
      fieldType === FIELD_TYPE.CHECKBOX_GROUP &&
      value?.length === 0 &&
      isRequired
    ) {
      return t('validationErrMsg.checkbox_group_err_msg');
    } else if (fieldProps?.validations?.email && !REGEX.EMAIL.test(value)) {
      return t('validationErrMsg.valid_email_err_msg');
    } else if (fieldProps?.validations?.url && !REGEX.URL.test(value)) {
      return t('validationErrMsg.valid_url_err_msg');
    } else if (
      !REGEX.NUMBER_ONLY.test(value) &&
      fieldType === FIELD_TYPE.NUMBER_ONLY
    ) {
      return t('validationErrMsg.valid_number_err_msg');
    } else if (
      fieldType === FIELD_TYPE.INTEGER_ONLY &&
      !REGEX.NUMBER_INTEGER.test(value)
    ) {
      return t('validationErrMsg.valid_integer_err_msg');
    } else if (
      (fieldType === FIELD_TYPE.NUMBER_ONLY ||
        fieldType === FIELD_TYPE.INTEGER_ONLY) &&
      fieldProps?.validations?.minValue &&
      Number(value) < fieldProps?.validations?.minValue
    ) {
      return (
        fieldProps?.errorMessages?.minValueErrMsg ||
        t('validationErrMsg.min_value_err_msg', {
          fieldLabel,
          minValue: fieldProps?.validations?.minValue
        })
      );
    } else if (
      (fieldType === FIELD_TYPE.NUMBER_ONLY ||
        fieldType === FIELD_TYPE.INTEGER_ONLY) &&
      fieldProps?.validations?.maxValue &&
      Number(value) > fieldProps?.validations?.maxValue
    ) {
      return (
        fieldProps?.errorMessages?.maxValueErrMsg ||
        t('validationErrMsg.max_value_err_msg', {
          fieldLabel,
          maxValue: fieldProps?.validations?.maxValue
        })
      );
    } else if (
      fieldType === FIELD_TYPE.UPLOAD_BUTTON &&
      isRequired &&
      (!value || value.length === 0)
    ) {
      return t('validationErrMsg.upload_file_required_err_msg', { fieldLabel });
    } else if (
      fieldType === FIELD_TYPE.PASSWORD &&
      fieldProps?.validations?.password &&
      !REGEX.PASSWORD.test(value)
    ) {
      return (
        <List className="passwordRequirementList">
          <ListItem>
            At least one digit
            <code className="passwordRequirement">(0-9)</code>.
          </ListItem>
          <ListItem>
            No
            <code className="passwordRequirement">whitespace characters</code>.
          </ListItem>
          <ListItem>
            Minimum length of
            <code className="passwordRequirement">8 characters</code>.
          </ListItem>
          <ListItem>
            At least one uppercase letter
            <code className="passwordRequirement">(A-Z)</code>.
          </ListItem>
          <ListItem>
            At least one lowercase letter
            <code className="passwordRequirement">(a-z)</code>.
          </ListItem>
          <ListItem>
            At least one special character from the set
            <code className="passwordRequirement">
              !@#$%^&*()-_=+{};:,&lt;.&gt;
            </code>
            .
          </ListItem>
        </List>
      );
    } else if (
      fieldProps?.validations?.regex &&
      !new RegExp(fieldProps?.validations?.regex).test(value)
    ) {
      return (
        fieldProps?.errorMessages?.regexErrMsg ||
        t('validationErrMsg.valid_data_err_msg')
      );
    } else if (
      fieldProps?.validations?.uniqueDataValidation &&
      fieldProps?.uniqueDataKey &&
      value
    ) {
      const result = fieldProps?.uniqueData?.filter((item) =>
        item[fieldProps?.uniqueDataKey]?.includes(value)
      );
      if (result && Object.keys(result)?.length) {
        return t('validationErrMsg.unique_data_err_msg', {
          fieldLabel: fieldLabel.toLowerCase()
        });
      }
    } else if (value && fieldProps?.validations?.json) {
      try {
        JSON.parse(value);
      } catch (error) {
        return fieldProps?.errorMessages?.jsonErrMsg;
      }
    } else if (value && fieldProps?.type === FIELD_TYPE.REGEX) {
      try {
        new RegExp(value);
      } catch (error) {
        return t('validationErrMsg.valid_regex_err_msg');
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const getInputProps = () => {
    if (!fieldProps?.errorMessages?.maxLenghtErrMsg) {
      return {
        maxLength: fieldProps?.validations?.maxLength
      };
    }

    if (!fieldProps?.errorMessages?.minLengthErrMsg) {
      return {
        minLength: fieldProps?.validations?.minLength
      };
    }

    return {};
  };
  const renderFormField = (
    field: any,
    meta: any,
    fieldType: string,
    form: any
  ) => {
    switch (fieldType) {
      case FIELD_TYPE.FREE_TEXT:
      case FIELD_TYPE.TEXT:
      case FIELD_TYPE.ALPHA_NUMBERICS:
      case FIELD_TYPE.EMAIL:
      case FIELD_TYPE.REGEX:
      case FIELD_TYPE.PASSWORD:
      case FIELD_TYPE.NUMBER_ONLY:
      case FIELD_TYPE.TEXTAREA:
      case FIELD_TYPE.INTEGER_ONLY:
      case FIELD_TYPE.URL:
        return (
          <TextField
            {...field}
            fullWidth
            type={
              (fieldProps?.type === FIELD_TYPE.PASSWORD && showPassword) ||
              fieldProps?.type === FIELD_TYPE.FREE_TEXT
                ? FIELD_TYPE.TEXT
                : fieldProps?.type === FIELD_TYPE.INTEGER_ONLY ||
                  fieldProps?.type === FIELD_TYPE.NUMBER_INTEGER ||
                  fieldProps?.type === FIELD_TYPE.NUMBER_ONLY
                ? 'number'
                : fieldProps?.type
            }
            autoComplete="off"
            onKeyDown={fieldProps?.handleKeyDown}
            disabled={fieldProps?.isDisabled}
            label={fieldProps?.label}
            error={meta.touched && meta.error !== undefined}
            helperText={meta.touched && meta.error}
            inputProps={getInputProps()}
            InputProps={
              fieldProps?.type === FIELD_TYPE.PASSWORD
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                : {}
            }
            multiline={fieldProps?.type === FIELD_TYPE.TEXTAREA}
            rows={
              fieldProps?.type === FIELD_TYPE.TEXTAREA &&
              fieldProps?.textareaRows
                ? fieldProps?.textareaRows
                : undefined
            }
            onChange={(event) => {
              handleOnChange(event, field, event?.target?.value);
            }}
            required={fieldProps?.validations?.required}
            placeholder={fieldProps?.placeholder}
          />
        );

      case FIELD_TYPE.SELECT:
        return (
          <Select
            {...field}
            fullWidth
            label={`${fieldProps?.label}`}
            disabled={fieldProps?.isDisabled}
            defaultValue={fieldProps?.options[0]?.id}
            value={field?.value || fieldProps?.options[0]?.id}
            options={fieldProps?.options || []}
            itemValue={fieldProps?.itemValueKey}
            itemText={fieldProps?.itemLabelKey}
            error={meta.touched && meta.error !== undefined}
            helperText={meta.touched && meta.error}
            onChange={(event) => {
              handleOnChange(event, field, event?.target?.value);
            }}
            required={fieldProps?.validations?.required}
          ></Select>
        );

      case FIELD_TYPE.RADIO:
        return (
          <FormControl>
            <FormLabel id={`${fieldProps?.id}-label`}>
              {fieldProps?.label}
            </FormLabel>
            <RadioGroup
              {...field}
              name={fieldProps?.name}
              row={fieldProps?.isDirectionRow}
              value={field?.value}
              onChange={(event) => handleOnChange(event, field)}
            >
              {fieldProps?.options?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option[fieldProps?.itemValueKey]}
                  control={<Radio />}
                  label={option[fieldProps?.itemLabelKey]}
                  disabled={option?.isDisabled || false}
                />
              ))}
            </RadioGroup>
            <ErrorMessage
              name={fieldProps?.name}
              component={'div'}
              className="errorState"
            />
          </FormControl>
        );

      case FIELD_TYPE.CHECKBOX:
        return (
          <FormControlLabel
            {...field}
            control={
              <CheckBox
                checked={field.value}
                onChange={(event) => handleOnChange(event, field, '', form)}
              />
            }
            label={fieldProps?.label}
          />
        );

      case FIELD_TYPE.AUTOCOMPLETE:
        return (
          <Autocomplete
            {...field}
            value={value || ''}
            options={fieldProps?.options || []}
            disablePortal={true}
            getOptionLabel={(option) => option[fieldProps?.itemLabelKey] || ''}
            isOptionEqualToValue={(option, value) =>
              option[fieldProps?.itemValueKey] ===
              value[fieldProps?.itemValueKey]
            }
            onChange={(_event, value) =>
              handleOnChange(_event, field, value, form)
            }
            onSelect={() => form.setFieldTouched(field.name, true)}
            renderInput={(params) => (
              <TextField
                label={fieldProps?.label}
                variant="outlined"
                error={meta.touched && meta.error !== undefined}
                helperText={meta.touched && meta.error}
                {...params}
              />
            )}
          />
        );

      case FIELD_TYPE.CHECKBOX_GROUP:
        return (
          <CheckboxGroup
            options={fieldProps?.options}
            field={field}
            legendTitle={fieldProps?.label}
            itemValueKey={fieldProps?.itemValueKey}
            itemLabelKey={fieldProps?.itemLabelKey}
            directionRow={fieldProps?.isDirectionRow}
            helperText={meta?.touched && meta?.error}
          />
        );

      case FIELD_TYPE.SWITCH:
        return (
          <FormControlLabel
            {...field}
            control={
              <Switch
                checked={field.value}
                onChange={(event) => handleOnChange(event, field)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={fieldProps?.label}
            labelPlacement="start"
          />
        );
      case FIELD_TYPE.MULTI_SELECT_ADD_OPTION:
        const [multiSelectAOOnFocuse, setMultiSelectAOOnFocuse] =
          useState(false);
        return (
          <AutocompleteTagsCreate
            className={multiSelectAOOnFocuse ? '' : 'multiselectformcontrol'}
            id={fieldProps?.id}
            title={fieldProps?.label}
            options={fieldProps?.options || []}
            value={field?.value || []}
            onChange={(_event, value, reason, details) => {
              handleOnSkillChange(_event, field, value, form, reason, details);
            }}
            onFocus={() => setMultiSelectAOOnFocuse(true)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={fieldProps?.label}
                onChange={(event) => {}}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <>
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option.label}
                    size="small"
                    deleteIcon={<Box className="icon-close closeIcon" />}
                    {...getTagProps({ index })}
                  />
                  {fieldProps?.multiSelectWithIcon &&
                    index + 1 < value?.length && (
                      <span
                        className={`${fieldProps?.multiSelectWithIcon} ml-5 mr-5`}
                      ></span>
                    )}
                </>
              ))
            }
            filterSelectedOptions
            isOptionEqualToValue={(option, value) =>
              option[fieldProps?.itemValueKey] ===
              value[fieldProps?.itemValueKey]
            }
          />
        );

      case FIELD_TYPE.MULTI_SELECT:
        const [focus, setFocus] = useState(false);
        return (
          <Autocomplete
            {...field}
            multiple
            limitTags={fieldProps.limitedTags || 3}
            className={focus ? '' : 'multiselectformcontrol'}
            value={field?.value || []}
            options={fieldProps?.options || []}
            getOptionLabel={(option) => option[fieldProps.itemLabelKey]}
            onChange={(_event, value) => {
              handleOnChange(_event, field, value, form);
            }}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) =>
              option[fieldProps?.itemValueKey] ===
              value[fieldProps?.itemValueKey]
            }
            error={meta.touched && meta.error !== undefined}
            helperText={meta.touched && meta.error}
            onFocus={() => setFocus(true)}
            freeSolo
            onBlur={() => setFocus(false)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <>
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option[fieldProps.itemLabelKey]}
                    size="small"
                    deleteIcon={<Box className="icon-close closeIcon" />}
                    {...getTagProps({ index })}
                  />
                  {fieldProps?.multiSelectWithIcon &&
                    index + 1 < value?.length && (
                      <span
                        className={`${fieldProps?.multiSelectWithIcon} ml-5 mr-5`}
                      ></span>
                    )}
                </>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={fieldProps?.label}
                variant="outlined"
                error={meta.touched && meta.error !== undefined}
                helperText={meta.touched && meta.error}
                onChange={(event) =>
                  handleOnChange(event, field, event?.target?.value, form)
                }
              />
            )}
          />
        );
      case FIELD_TYPE.DATE:
        return (
          <DatePicker
            {...field}
            label={fieldProps?.label}
            disableFuture={fieldProps?.disableFutureDate}
            disablePast={fieldProps?.disablePastDate}
            maxDate={fieldProps?.maxDate}
            minDate={fieldProps?.minDate}
            defaultValue={
              fieldProps?.defaultValue ? dayjs(fieldProps.defaultValue) : null
            }
            value={field?.value ? dayjs(field.value) : null}
            // value={field?.value}
            views={fieldProps.dateViews}
            onChange={(newValue) => {
              form.setFieldValue(field.name, newValue);
              handleOnChange(event, field, newValue, form);
            }}
            required={fieldProps?.validations?.required}
          />
        );
      case FIELD_TYPE.UPLOAD_BUTTON:
        return (
          <FileButton
            {...field}
            fileType={fieldProps.acceptedFileFormat}
            variant="contained"
            onChange={(_event, value) => {
              const file = _event[0];
              if (file && file?.name) {
                form.setFieldValue(field.name, file.name);
              }
              fieldProps.handleFileUpload(_event);
            }}
            buttonName={fieldProps.buttonName}
            label={fieldProps.label}
            isMultipleFile={fieldProps.isMultiple}
            fieldId={fieldProps.id}
            handleFileDelete={fieldProps.handleFileDelete}
            required={fieldProps?.validations?.required}
            enableDelete={fieldProps.enableDelete}
            maxFilesToUpload={fieldProps?.maxFilesToUpload}
          />
        );

      case FIELD_TYPE.TEXT_EDITOR:
        return (
          <TextEditorWrapper
            {...field}
            autoComplete="off"
            disabled={fieldProps?.isDisabled}
            label={fieldProps?.label}
            onChange={(value) => {
              handleOnChange(null, field, value, form);
            }}
            required={fieldProps?.validations?.required}
            placeholder={fieldProps?.placeholder}
          ></TextEditorWrapper>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Field
        name={fieldProps?.name}
        type={
          fieldProps?.type === FIELD_TYPE.NUMBER_ONLY
            ? FIELD_TYPE.TEXT
            : fieldProps?.type
        }
        validate={(value: string) => {
          return validateField(
            value,
            fieldProps?.type,
            fieldProps?.validations?.required,
            fieldProps?.label
          );
        }}
      >
        {({ field, meta, form }) =>
          renderFormField(field, meta, fieldProps?.type, form)
        }
      </Field>
      {/* Can be used in future so keeping this as a commented code */}
      {/* <ErrorMessage
        name={fieldProps?.name}
        component={'div'}
        className="errorState"
      /> */}
    </>
  );
};

export default FormField;
