import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SidebarMenus } from 'src/shared/components/index';
import { ROLES, USER_TYPE } from 'src/shared/constants/constants';
import * as ROUTES from 'src/shared/constants/routes';
import { selectUserState } from 'src/auth/login/services/userSlice';

const SidebarMenu = () => {
  const { loginUserRole, selectedOrganization, loginUserType } =
    useSelector(selectUserState);
  const [menuList, setMenuList] = useState([]);
  const organization = {
    pathname: ROUTES.ADMIN_LOGIN,
    subpathname: ROUTES.ORGANIZATION,
    icon: <span className="icon-users"></span>,
    submenuname: 'organization'
  };

  const adminManagement = {
    pathname: ROUTES.ADMIN_LOGIN,
    subpathname: ROUTES.ADMIN_MANAGEMENT,
    icon: <span className="icon-users"></span>,
    submenuname: 'admin_management'
  };

  const userManagement = {
    pathname: ROUTES.USER_MANAGEMENT,
    subpathname: '',
    icon: <span className="icon-users"></span>,
    submenuname: 'sidebar-menu.user_management'
  };

  const playground = {
    pathname: ROUTES.PLAYGROUND,
    subpathname: '',
    icon: <span className="icon-edit"></span>,
    submenuname: 'sidebar-menu.user_management'
  };

  //TODO : For future use

  // const dashboard = {
  //   pathname: ROUTES.DASHBOARD,
  //   subpathname: '',
  //   icon: <span className="icon-dashboard"></span>,
  //   submenuname: 'sidebar-menu.dashboard'
  // };

  const interviews = {
    pathname: ROUTES.INTERVIEWS,
    subpathname: '',
    icon: <span className="icon-interviews"></span>,
    submenuname: 'sidebar-menu.interviews'
  };

  const masterData = {
    pathname: ROUTES.MASTER_DATA,
    subpathname: '',
    icon: <span className="icon-master-data"></span>,
    submenuname: 'sidebar-menu.master_data'
  };

  const jds = {
    pathname: ROUTES.JD,
    subpathname: '',
    icon: <span className="icon-jobs"></span>,
    submenuname: 'sidebar-menu.jds'
  };

  const manageSlots = {
    pathname: ROUTES.MANAGE_SLOTS,
    subpathname: '',
    icon: <span className="icon-slot"></span>,
    submenuname: 'sidebar-menu.manage_slots'
  };

  useEffect(() => {
    const list = [];
    setMenuList([]);
    switch (loginUserRole) {
      case ROLES.SUPER_ADMIN:
        list.push(userManagement);
        break;
      case ROLES.TENANT_ADMIN:
        list.push(
          // dashboard,
          userManagement,
          interviews,
          jds,
          masterData
        );
        break;
      case ROLES.COMPANY_TECH:
        list.push(userManagement, jds, interviews);
        break;
      case ROLES.TENANT_OPERATOR:
        list.push(
          // dashboard,
          userManagement,
          interviews,
          jds
        );
        break;
      case ROLES.INTERVIEWER:
        list.push(interviews, manageSlots);
        break;
      case ROLES.CANDIDATE:
        list.push(interviews, jds);
        break;
      default:
        break;
    }
    setMenuList(list);
  }, [window.location.pathname, selectedOrganization]);
  return (
    <>
      <SidebarMenus menuname={''} option={menuList} />
    </>
  );
};

export default SidebarMenu;
