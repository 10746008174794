import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Hidden, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import { useAuth } from 'src/providers/AuthguardContext';
import { Typography } from 'src/shared/components/index';
import { useNavigate } from 'react-router';
import * as ROUTES from 'src/shared/constants/routes';
import { RootState } from 'src/store/reducer';
import { ROLES, getRole } from 'src/shared/constants/constants';
import { selectUserState } from 'src/auth/login/services/userSlice';

const HeaderUserbox = () => {
  const { userData, loginUserRole } = useSelector(selectUserState);

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const userLogout = async () => {
    await logout();
    localStorage.clear();
    navigate(
      loginUserRole === ROLES.ADMIN_OPERATOR ||
        loginUserRole === ROLES.SUPER_ADMIN
        ? ROUTES.ADMIN_LOGIN
        : ROUTES.LOGIN
    );
  };

  return (
    <>
      <Button color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          sx={{ width: '34px', height: '34px' }}
          variant="circular"
          alt={userData?.name}
          src={userData?.imageUrl}
        />
        <Hidden mdDown>
          <Box className="UserBoxText d-flex flex-column-start align-items-start">
            <Typography className="UserBoxLabel" variant="body1">
              {userData?.name}
            </Typography>
            <Typography className="UserBoxDescription" variant="body2">
              {getRole(loginUserRole)}
            </Typography>
          </Box>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon className="UserBoxDropIcon" sx={{ ml: 2 }} />
        </Hidden>
      </Button>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        className="userSettingDropdown"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ m: 2 }} className="dropBox">
          {[ROLES.INTERVIEWER, ROLES.CANDIDATE].includes(loginUserRole) && (
            <Box
              sx={{ mb: 2 }}
              className="d-flex align-items-center cursorPointer "
              onClick={() => navigate(ROUTES.PROFILE)}
            >
              <PersonOutlineTwoToneIcon sx={{ mr: 1 }} />
              Profile
            </Box>
          )}
          <Box
            className="d-flex align-items-center cursorPointer "
            onClick={userLogout}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserbox;
