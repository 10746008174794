import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPut,
  ApiResponse
} from 'src/shared/utils/api-request';
import { Pagination } from 'src/core/model/pagination.dto';
import { CandidateStatus } from '../models';

export const getInterviewDataAPI = async (payload: {
  paginationData: Pagination;
  filter: string;
  candidateId?: string;
}) => {
  const res = await apiGet(
    `/interview?filter=${payload.filter}&page=${
      payload.paginationData.page
    }&limit=${payload.paginationData.limit}${
      payload.paginationData.searchText
        ? `&search=${payload.paginationData.searchText}`
        : ''
    }${payload.candidateId ? `&candidateId=${payload.candidateId}` : ''}`
  );
  return res.data;
};

export const interviewAcceptanceStatusUpdateAPI = async (payload: {
  id: string;
  status: string;
}) => {
  const res = await apiPatch(`/interview/${payload.id}/update-status`, {
    status: payload.status
  });
  return res.data;
};

export const deleteInterviewAPI = async (payload: { id: string }) => {
  const res = await apiDelete(`/interview/${payload.id}`);
  return res.data;
};
export const interviewDetailsAPI = async (interviewId: string) => {
  const res = await apiGet(`/interview/${interviewId}/interview-details`);
  return res.data;
};

export const updateInterviewDetailsAPI = async (
  payload: any
): Promise<ApiResponse<any>> => {
  const { detailId, ...data } = payload;
  const res = await apiPut(`/interview/interview-details/${detailId}`, data);
  return res.data;
};

export const updateCandidateStatusAPI = async (
  payload: CandidateStatus,
  interviewId: string
) => {
  const res = await apiPatch(
    `/interview/${interviewId}/update-candidate-status`,
    payload
  );
  return res.data;
};
