import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import { Box } from '@mui/material';
import { ChangeEvent, createRef } from 'react';
import CalenderHeader from './CalenderHeader';
import CalenderEvent from './CalenderEvent';
import CustomWeekHeader from './CustomWeekHeader';
import { AvailabilityData } from 'src/modules/ManageSlots/models';

export type FullCalendarTypeProp = {
  events: any;
  handleEventClick?: (arg: EventClickArg) => void;
  handleSlotClick?: (arg: DateSelectArg) => void;
  handleDatesChange?: (arg: DatesSetArg) => void;
  handleDayAvailability?: (
    checked: boolean,
    date: Date,
    weekStartDate: string,
    weekEndDate: string
  ) => void;
  displayHeaderControls?: boolean;
  displayWeekHeaderDate?: boolean;
  headerTitle?: string;
  showLegends: boolean;
  weekHeaderData?: AvailabilityData[];
};

export const Calendar = ({
  events,
  handleEventClick,
  handleSlotClick,
  handleDatesChange,
  handleDayAvailability,
  displayHeaderControls = true,
  displayWeekHeaderDate = true,
  headerTitle,
  showLegends,
  weekHeaderData
}: FullCalendarTypeProp) => {
  //constants
  const calendarRef = createRef<FullCalendar>();

  return (
    <>
      <Box className="calenderWrapper">
        <CalenderHeader
          calendarRef={calendarRef}
          title={headerTitle}
          isControls={displayHeaderControls}
          displayLegends={showLegends}
        />
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          slotDuration={'00:30:00'}
          slotLabelInterval={'00:30'}
          allDaySlot={false}
          scrollTime={'08:00:00'} // default scroll to specific time
          weekends={true} // displays the weekends
          events={events}
          eventContent={(props) => <CalenderEvent {...props} />}
          dayHeaderContent={(props) => (
            <CustomWeekHeader
              {...props}
              displayDate={displayWeekHeaderDate}
              handleStatusClick={handleDayAvailability}
              weekHeaderData={weekHeaderData}
            />
          )}
          headerToolbar={false} // disable default header
          eventClick={handleEventClick} // triggers when a event gets clicked
          editable={false} // for restricting drag or resize of events
          selectMirror={false}
          selectable={true} // required for using select
          select={handleSlotClick} // for selecting a slot
          // selectAllow={() => false}
          datesSet={handleDatesChange} // for getting visible date range
        />
      </Box>
    </>
  );
};
export default Calendar;
