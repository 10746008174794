import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import organizationReducer from 'src/modules/Organization/services/Company.service';
import userManagementDataReducer from 'src/modules/user-management/services/UserManagement.service';
import adminUserDataReducer from 'src/modules/AdminManagement/services/adminManagement.service';
import userReducer from 'src/auth/login/services/userSlice';
import jdReducer from 'src/modules/JD/services/jd.service';
import ManageSlotsReducer from 'src/modules/ManageSlots/services/ManageSlots.service';
import interviewReducer from 'src/modules/Interviews/services/Interviews.service';
import storage from 'redux-persist/lib/storage';
import interviewCallReducer from 'src/modules/InterviewCall/services/InterviewCall.service';
import masterDataReducer from 'src/modules/MasterData/services/masterData.service';

const combinedReducer = combineReducers({
  user: userReducer,
  organizationsData: organizationReducer,
  userData: userReducer,
  userManagementData: userManagementDataReducer,
  adminUserData: adminUserDataReducer,
  jdData: jdReducer,
  manageSlots: ManageSlotsReducer,
  interviews: interviewReducer,
  interviewCallData: interviewCallReducer,
  masterData: masterDataReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
