export const LOGIN = '/';
export const STYLE_GUIDE = '/style-guide';
export const SET_PASSWORD = '/set-password';
export const FORGET_PASSWORD = '/forget-password';
export const ADMIN_FORGET_PASSWORD = '/admin/forget-password';
export const CHECK_YOUR_EMAIL = '/check-your-email';
export const ERROR_PAGE = '/not-found';

export const ADMIN_LOGIN = '/admin';
export const CANDIDATE_LOGIN = '/candidate';
export const TENANT_LOGIN = '/tenant';

export const DASHBOARD = '/dashboards';

export const ORGANIZATION = '/organization';
export const ADMIN_MANAGEMENT = '/admin-management';

export const USER_MANAGEMENT = '/user-management';
export const WELCOME = '/welcome';
export const PROFILE = '/profile';
export const PLAYGROUND = 'playground';

export const INTERVIEWS = '/interviews';
export const MASTER_DATA = '/master-data';
export const JD = '/job-description';
export const VIEW_JD_DETAILS = '/details';
export const JD_PICK_CANDIDATE = 'pick-candidate';
export const REPORTS = '/reports';
export const MANAGE_SLOTS = '/manage-slots';
export const ATTACH_JD = '/attach-jd';
export const INTERVIEW_CALL = '/interview-call';
