import { apiGet, apiPatch } from 'src/shared/utils/api-request';

export const getInterviewCallDetailsApi = async (interviewId: string) => {
  const res = await apiGet(`interview/${interviewId}/interview-call-details`);
  return res.data;
};

export const saveInterviewCallNotesAPI = async (payload: {
  interviewId: string;
  notes: string;
}) => {
  const res = await apiPatch(`interview/interview-call-details`, payload);
  return res.data;
};
