import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';
import { GuardedRoute } from './guarded-routes';
import * as MODULE from './../shared/constants/constants';
import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(lazy(() => MODULE.LOGIN));
const ErrorPage = Loader(lazy(() => MODULE.ERROR_PAGE));
const StyleGuide = Loader(lazy(() => MODULE.STYLE_GUIDE));
const Jd = Loader(lazy(() => MODULE.JD));
const ViewJdDetailsLayout = Loader(lazy(() => MODULE.VIEW_JD_DETAILS));
const JdPickCandidate = Loader(lazy(() => MODULE.JD_PICK_CANDIDATE));
const Playground = Loader(lazy(() => MODULE.PLAYGROUND));

// Dashboards
const UserManagement = Loader(lazy(() => MODULE.USER_MANAGEMENT));
const Profile = Loader(lazy(() => MODULE.PROFILE));
const Welcome = Loader(lazy(() => MODULE.WELCOME));
const Interviews = Loader(lazy(() => MODULE.INTERVIEWS));
const ManageSlots = Loader(lazy(() => MODULE.MANAGE_SLOTS));
const Dashboard = Loader(lazy(() => MODULE.DASHBOARD));
const InterviewCall = Loader(lazy(() => MODULE.INTERVIEW_CALL));
const MasterData = Loader(lazy(() => MODULE.MASTER_DATA));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.STYLE_GUIDE,
        element: <StyleGuide />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.CANDIDATE_LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: `${ROUTES.WELCOME}`,
        element: <GuardedRoute component={Welcome} />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout className="admin-login" />,
    children: [
      {
        path: ROUTES.ADMIN_LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: `${ROUTES.DASHBOARD}`,
        // element: (
        //   <Navigate
        //     to={`${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}`}
        //     replace
        //   />
        // )
        element: <GuardedRoute component={Dashboard} />
      },
      {
        path: `${ROUTES.USER_MANAGEMENT}`,
        children: [
          {
            path: '',
            element: <GuardedRoute component={UserManagement} />
          },
          {
            path: `attach-jd`,
            element: <GuardedRoute component={Jd} />
          }
        ]
      },
      {
        path: `${ROUTES.INTERVIEWS}`,
        element: <GuardedRoute component={Interviews} />
      },
      {
        path: `${ROUTES.JD}`,
        element: <GuardedRoute component={Jd} />
      },
      {
        path: `${ROUTES.JD}${ROUTES.VIEW_JD_DETAILS}`,
        children: [
          {
            path: '',
            element: <GuardedRoute component={ViewJdDetailsLayout} />
          },
          {
            path: `${ROUTES.JD_PICK_CANDIDATE}`,
            element: <GuardedRoute component={JdPickCandidate} />
          }
        ]
      },
      {
        path: `${ROUTES.MASTER_DATA}`,
        element: <GuardedRoute component={MasterData} />
      },
      {
        path: `${ROUTES.REPORTS}`,
        element: <GuardedRoute component={StyleGuide} />
      },
      {
        path: `${ROUTES.PROFILE}`,
        element: <GuardedRoute component={Profile} />
      },
      {
        path: `${ROUTES.MANAGE_SLOTS}`,
        element: <GuardedRoute component={ManageSlots} />
      },
      {
        path: `${ROUTES.INTERVIEW_CALL}`,
        children: [
          {
            path: '',
            element: <GuardedRoute component={InterviewCall} />
          },
          {
            path: `${ROUTES.PLAYGROUND}`,
            element: <GuardedRoute component={Playground} />
          }
        ]
      }
    ]
  },
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  }
];

export default routes;
