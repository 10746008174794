import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  updateTenantUserStatusAPI,
  addUserDataAPI,
  saveUserEducationDetails,
  editUserEducation,
  deleteUserEducation,
  getUserEducation,
  saveUserAdditionalInfoAPI,
  saveUserCertificates,
  editUserCertificates,
  deleteUserCertificates,
  getUserCertificates,
  saveUserWorkExperienceDetails,
  deleteWorkExperience,
  getUserWorkExperience,
  editUserWorkExperience,
  updateUserDataAPI,
  saveUserSkills,
  editUserSkills,
  deleteUserSkills,
  getUserSkills,
  saveLinkAccountAPI,
  getLinkAccountsAPI,
  deleteLinkAccountAPI,
  uploadFileAPI,
  toggleCandidateStatusAPI,
  getUploadedUserDocuments,
  deleteDocumentsAPI,
  downloadDocumentAPI,
  getDoucmentsByCategory,
  updateUserProfilePicAPI,
  getUsersDataAPI,
  getUserDataAPI,
  resendInviteEmailAPI,
  updateUserDocumentDetailsAPI,
  saveAttachedJdAPI,
  getAttachedJdAPI,
  saveUserNotes,
  getUserNotesDetails,
  deleteUserNotesDetails,
  updateUserNotesDetails
} from '../apis/UserManagementAPI';
import {
  PreviousScreenType,
  UserData,
  UserDocument,
  UserLinkAccount
} from '../models';
import { RootState } from 'src/store/reducer';
import {
  Pagination,
  PaginationResponseDto,
  initalPaginationData
} from 'src/core/model/pagination.dto';
import {
  DOCUMENT_CATEGORY,
  selectedUserDataInitialState,
  USER_ROLES
} from 'src/shared/constants/constants';
import { ApiResponse } from 'src/shared/utils/api-request';

export interface UserManagementState {
  selectedUserData: UserData;
  candidateListData: PaginationResponseDto<UserData>;
  interviewerListData: PaginationResponseDto<UserData>;
  techListData: PaginationResponseDto<UserData>;
  tagListData: PaginationResponseDto<UserData>;
  previousScreen: PreviousScreenType;
  isUserListUpdated: boolean;
  isUserBasicInformationSaved: boolean;
  isError: boolean;
  successMessage: string;
  errorMessage: string;
  linkAccountData: UserLinkAccount[];
  uploadedUserProfilePicture: [];
  uploadedWorkAuthorizationDocument: [];
  uploadedResume: [];
}

const initialState: UserManagementState = {
  selectedUserData: selectedUserDataInitialState,
  candidateListData: initalPaginationData,
  interviewerListData: initalPaginationData,
  techListData: initalPaginationData,
  tagListData: initalPaginationData,
  previousScreen: { role: '', tab: null },
  isUserListUpdated: false,
  isUserBasicInformationSaved: false,
  isError: false,
  successMessage: '',
  errorMessage: '',
  linkAccountData: [],
  uploadedUserProfilePicture: [],
  uploadedWorkAuthorizationDocument: [],
  uploadedResume: []
};
export const selectorUserManagementState = ({
  userManagementData
}: RootState) => ({
  selectedUserData: userManagementData?.selectedUserData,
  candidateListData: userManagementData?.candidateListData,
  interviewerListData: userManagementData?.interviewerListData,
  techListData: userManagementData?.techListData,
  tagListData: userManagementData?.tagListData,
  previousScreen: userManagementData?.previousScreen,
  isUserListUpdated: userManagementData?.isUserListUpdated,
  isUserBasicInformationSaved: userManagementData?.isUserBasicInformationSaved,
  isError: userManagementData?.isError,
  successMessage: userManagementData?.successMessage,
  errorMessage: userManagementData?.errorMessage,
  uploadedUserProfilePicture: userManagementData?.uploadedUserProfilePicture,
  uploadedWorkAuthorizationDocument:
    userManagementData?.uploadedWorkAuthorizationDocument,
  uploadedResume: userManagementData?.uploadedResume
});

export const getUser = createAsyncThunk(
  'UserManagementData/getUser',
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const response = await getUserDataAPI(payload.id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  'UserManagementData/addUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await addUserDataAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  'UserManagementData/updateUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateUserDataAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleCandidateStatus = createAsyncThunk(
  'UserManagementData/toggleCandidateStatus',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await toggleCandidateStatusAPI(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const findCandidateData = createAsyncThunk(
  'UserManagementData/findCandidateData',
  async (payload: Pagination) => {
    const response = await getUsersDataAPI(payload, USER_ROLES.candidate);
    return response;
  }
);

export const getUserEducationDetials = createAsyncThunk(
  'UserManagementData/getUserEducationDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getUserEducation(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserEducationDetails = createAsyncThunk(
  'UserManangementData/addUserEducation',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveUserEducationDetails(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUserEducationDetails = createAsyncThunk(
  'UserManagementData/editUserEducation',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editUserEducation(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserEducationDetails = createAsyncThunk(
  'UserManagementData/deleteUserEducation',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserEducation(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserWorkExperienceDetials = createAsyncThunk(
  'UserManagementData/getUserWorkExperienceDetials',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getUserWorkExperience(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addUserWorkExperienceDetails = createAsyncThunk(
  'UserManangementData/addUserWorkExperienceDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveUserWorkExperienceDetails(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editUserWorkExperienceDetails = createAsyncThunk(
  'UserManagementData/editUserWorkExperienceDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editUserWorkExperience(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserWorkExperienceDetails = createAsyncThunk(
  'UserManagementData/deleteUserWorkExperienceDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteWorkExperience(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserAdditionalInfo = createAsyncThunk(
  'userManangementData/saveUserAdditionalInfo',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await saveUserAdditionalInfoAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserCertificateDetials = createAsyncThunk(
  'UserManagementData/getUserCertificateDetials',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getUserCertificates(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserCertificateDetails = createAsyncThunk(
  'UserManangementData/addUserCertificateDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveUserCertificates(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUserCertificateDetails = createAsyncThunk(
  'UserManagementData/editUserCertificateDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editUserCertificates(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserCertificateDetails = createAsyncThunk(
  'UserManagementData/deleteUserCertificateDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserCertificates(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserSkillDetials = createAsyncThunk(
  'UserManagementData/getUserSkillDetials',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getUserSkills(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserSkillDetails = createAsyncThunk(
  'UserManangementData/addUserSkillDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveUserSkills(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUserSkillDetails = createAsyncThunk(
  'UserManagementData/editUserSkillDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editUserSkills(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserSkillDetails = createAsyncThunk(
  'UserManagementData/deleteUserSkillDetails',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserSkills(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserLinkAccount = createAsyncThunk(
  'UserManangementData/addUserLinkAccount',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveLinkAccountAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserLinkAccounts = createAsyncThunk(
  'UserManagementData/getUserLinkAccounts',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getLinkAccountsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserLinkAccount = createAsyncThunk(
  'UserManagementData/deleteUserLinkAccount',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteLinkAccountAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadUserProfilePicture = createAsyncThunk(
  'UserManagementData/uploadUserProfilePicture',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfilePic = createAsyncThunk(
  'UserManagementData/updateUserProfilePic',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateUserProfilePicAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserNotes = createAsyncThunk(
  'UserManagementData/getUserNotes',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getUserNotesDetails(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addUserNotes = createAsyncThunk(
  'UserManangementData/addUserNotes',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveUserNotes(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUserNotes = createAsyncThunk(
  'UserManagementData/updateUserNotes',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateUserNotesDetails(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserNotes = createAsyncThunk(
  'UserManagementData/deleteUserNotes',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserNotesDetails(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get profile pic, resume and work authorization of user.
export const getBasicInformationDocuments = createAsyncThunk(
  'UserManagementData/getUserProfilePicture',
  async (payload: any, { rejectWithValue }) => {
    try {
      const profilepic = await getDoucmentsByCategory(
        payload.id,
        DOCUMENT_CATEGORY.profilePic
      );
      const candidateResume = await getDoucmentsByCategory(
        payload.id,
        DOCUMENT_CATEGORY.candidateResume
      );
      const canidateWorkAuthorizationDoc = await getDoucmentsByCategory(
        payload.id,
        DOCUMENT_CATEGORY.canidateWorkAuthorizationDoc
      );
      const profilePicrUrl = profilepic[0]?.id
        ? await downloadDocumentAPI(profilepic[0]?.id)
        : [];
      return {
        profilePicDoc: profilePicrUrl,
        resumeDoc: candidateResume,
        workAuthDoc: canidateWorkAuthorizationDoc
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadUserResume = createAsyncThunk(
  'UserManagementData/uploadUserResume',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadUserWorkAuthorizationFile = createAsyncThunk(
  'UserManagementData/uploadUserWorkAuthorizationFile',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadUserDocument = createAsyncThunk(
  'UserManagementData/uploadUserDocument',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserDocument = createAsyncThunk(
  'UserManagementData/updateUserDocument',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateUserDocumentDetailsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDocuments = createAsyncThunk(
  'UserManagementData/getUserDocuments',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getUploadedUserDocuments(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteUserDocumentsDetails = createAsyncThunk(
  'UserManagementData/deleteUserDocumentsDetails',
  async (payload: UserDocument, { rejectWithValue }) => {
    try {
      const response: ApiResponse<any> = await deleteDocumentsAPI(payload?.id);
      response.data.category = payload.category;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllInterviewersList = createAsyncThunk(
  'UserManagementData/getAllInterviewersList',
  async (payload: Pagination) => {
    const response = await getUsersDataAPI(payload, USER_ROLES.interviewer);
    return response;
  }
);

export const getAllTechsList = createAsyncThunk(
  'UserManagementData/getAllTechsList',
  async (payload: Pagination) => {
    const response = await getUsersDataAPI(payload, USER_ROLES.tech);
    return response;
  }
);

export const getAllTagsList = createAsyncThunk(
  'UserManagementData/getAllTagsList',
  async (payload: Pagination) => {
    const response = await getUsersDataAPI(payload, USER_ROLES.tag);
    return response;
  }
);

export const resendInviteEmail = createAsyncThunk(
  'userManangementData/resendInviteEmail',
  async (payload: UserData, { rejectWithValue }) => {
    try {
      const response = await resendInviteEmailAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveAttachedJd = createAsyncThunk(
  'JdData/saveAttachedJd',
  async (payload: { jdId: string; userIds: string[] }, { rejectWithValue }) => {
    try {
      const jdId = payload.jdId;
      delete payload.jdId;
      const response = await saveAttachedJdAPI(payload, jdId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAttachedJd = createAsyncThunk(
  'JdData/getAttachedJd',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await getAttachedJdAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userManagementSlice = createSlice({
  name: 'userManagementData',
  initialState,
  reducers: {
    setIsUserListUpdated: (state: any, action) => {
      state.isUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setSelectedUserData: (state: any, action) => {
      state.selectedUserData = action.payload;
    },
    setIsUserBasicInformationSaved: (state: any, action) => {
      state.isUserBasicInformationSaved = action.payload;
    },
    setSuccessMessageEmpty: (state: any, action) => {
      if (!action.payload) {
        state.successMessage = '';
      }
    },
    setSelectedUserDetailsEmpty: (state: any, action) => {
      if (!action.payload) {
        state.selectedUserData = selectedUserDataInitialState;
        state.uploadedUserProfilePicture = [];
        state.uploadedResume = [];
        state.uploadedWorkAuthorizationDocument = [];
      }
    },
    setPreviousScreen: (state: UserManagementState, action) => {
      if (!action.payload) {
        state.previousScreen = { role: '', tab: null };
      } else {
        state.previousScreen = action.payload;
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {})
      .addCase(getUser.fulfilled, (state: any, action) => {
        state.selectedUserData = action.payload.data;
      })
      .addCase(getUser.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addUser.pending, (state) => {})
      .addCase(addUser.fulfilled, (state: any, action) => {
        state.selectedUserData = action.payload.data;
        state.successMessage = action.payload.message;
        state.isUserBasicInformationSaved = true;
      })
      .addCase(addUser.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateUser.pending, (state) => {})
      .addCase(updateUser.fulfilled, (state: any, action) => {
        state.selectedUserData = action.payload.data;
        state.isUserBasicInformationSaved = true;
      })
      .addCase(updateUser.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateUserProfilePic.fulfilled, (state: any, action) => {
        const selectedUserData: UserData = state.selectedUserData;
        selectedUserData.profilePicDoc = action.payload.data;
      })
      .addCase(updateUserProfilePic.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(findCandidateData.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(findCandidateData.fulfilled, (state: any, action) => {
        if (action.payload) {
          let candidateData = action.payload;
          state.candidateListData = candidateData;
        }
      })
      .addCase(findCandidateData.rejected, (state) => {
        state.candidateListData = [];
      })

      .addCase(toggleCandidateStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(toggleCandidateStatus.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.isUserListUpdated = true;
          state.selectedUserData.isEnabled = !state.selectedUserData.isEnabled; // this will inverse the status in the redux store when the user will toggle the button fron the userDetails view. (which is in AddUser.tsx, line no. 406)
        }
      })
      .addCase(toggleCandidateStatus.rejected, (state) => {
        state.candidateListData = [];
      })
      .addCase(addUserEducationDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addUserEducationDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(addUserEducationDetails.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserEducationDetials.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserEducationDetials.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let educationData = action.payload.data;
          state.selectedUserData.educationListData = educationData;
        }
      })
      .addCase(getUserEducationDetials.rejected, (state, action: any) => {
        state.selectedUserData.educationListData = [];
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editUserEducationDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(editUserEducationDetails.fulfilled, (state, action: any) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(editUserEducationDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserEducationDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteUserEducationDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(deleteUserEducationDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addUserWorkExperienceDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addUserWorkExperienceDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(
        addUserWorkExperienceDetails.rejected,
        (state: any, action: any) => {
          state.loadingUserData = false;
          state.isError = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(getUserWorkExperienceDetials.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserWorkExperienceDetials.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let workExperienceData = action.payload.data;
          state.selectedUserData.workExperienceListData = workExperienceData;
        }
      })
      .addCase(getUserWorkExperienceDetials.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editUserWorkExperienceDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(
        editUserWorkExperienceDetails.fulfilled,
        (state, action: any) => {
          state.loadingUserData = false;
          state.successMessage = action.payload.message;
        }
      )
      .addCase(editUserWorkExperienceDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserWorkExperienceDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(
        deleteUserWorkExperienceDetails.fulfilled,
        (state: any, action) => {
          state.loadingUserData = false;
          state.successMessage = action.payload.message;
        }
      )
      .addCase(
        deleteUserWorkExperienceDetails.rejected,
        (state, action: any) => {
          state.loadingUserData = false;
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(saveUserAdditionalInfo.pending, (state) => {
        state.loadingUserInfoData = true;
        state.isUserInfoUpdated = false;
      })
      .addCase(saveUserAdditionalInfo.fulfilled, (state: any, action) => {
        state.loadingUserInfoData = false;
        state.isUserInfoUpdated = true;
        state.selectedUserData = action?.payload?.data;
        state.successMessage = action.payload.message;
      })
      .addCase(saveUserAdditionalInfo.rejected, (state, action: any) => {
        state.loadingUserInfoData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addUserCertificateDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addUserCertificateDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(
        addUserCertificateDetails.rejected,
        (state: any, action: any) => {
          state.loadingUserData = false;
          state.isError = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(getUserCertificateDetials.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserCertificateDetials.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let certificatesData = action.payload.data;
          state.selectedUserData.certificateListData = certificatesData;
        }
      })
      .addCase(getUserCertificateDetials.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editUserCertificateDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(editUserCertificateDetails.fulfilled, (state, action: any) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(editUserCertificateDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserCertificateDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteUserCertificateDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(deleteUserCertificateDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addUserSkillDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addUserSkillDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(addUserSkillDetails.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserSkillDetials.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserSkillDetials.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let skillsData = action.payload.data;
          state.selectedUserData.userSkillsData = skillsData;
        }
      })
      .addCase(getUserSkillDetials.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editUserSkillDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(editUserSkillDetails.fulfilled, (state, action: any) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(editUserSkillDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserSkillDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteUserSkillDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;

        state.successMessage = action.payload.message;
      })
      .addCase(deleteUserSkillDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addUserLinkAccount.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addUserLinkAccount.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(addUserLinkAccount.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserLinkAccounts.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserLinkAccounts.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let linkList = action.payload.data;
          state.selectedUserData.linkAccountList = linkList;
        }
      })
      .addCase(getUserLinkAccounts.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserLinkAccount.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteUserLinkAccount.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
      })
      .addCase(deleteUserLinkAccount.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadUserProfilePicture.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(uploadUserProfilePicture.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        if (action.payload) {
          state.uploadedUserProfilePicture = action.payload;
        }
      })
      .addCase(uploadUserProfilePicture.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadUserResume.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(uploadUserResume.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        if (action.payload) {
          state.uploadedResume = [...action.payload, ...state.uploadedResume];
        }
      })
      .addCase(uploadUserResume.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadUserWorkAuthorizationFile.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(
        uploadUserWorkAuthorizationFile.fulfilled,
        (state: any, action) => {
          state.loadingOrganizationData = false;
          if (action.payload) {
            state.uploadedWorkAuthorizationDocument = action.payload;
          }
        }
      )
      .addCase(
        uploadUserWorkAuthorizationFile.rejected,
        (state, action: any) => {
          state.loadingOrganizationData = false;
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(getBasicInformationDocuments.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(getBasicInformationDocuments.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        if (action.payload) {
          state.uploadedUserProfilePicture = action.payload.profilePicDoc;
          state.uploadedResume = action.payload.resumeDoc;
          state.uploadedWorkAuthorizationDocument = action.payload.workAuthDoc;
        }
      })
      .addCase(getBasicInformationDocuments.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadUserDocument.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(uploadUserDocument.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          state.successMessage = action.payload.message;
        }
      })
      .addCase(uploadUserDocument.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateUserDocument.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateUserDocument.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          state.successMessage = action.payload.message;
        }
      })
      .addCase(updateUserDocument.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserDocuments.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserDocuments.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let documentListData = action.payload.data;
          state.selectedUserData.userDocumentListData = documentListData;
        }
      })
      .addCase(getUserDocuments.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserDocumentsDetails.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteUserDocumentsDetails.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.message;
        if (
          action.payload.data.category === DOCUMENT_CATEGORY.candidateResume
        ) {
          state.uploadedResume = state.uploadedResume.filter(
            (file) => file.id !== action.payload.data.id
          );
        }
      })
      .addCase(deleteUserDocumentsDetails.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAllInterviewersList.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getAllInterviewersList.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let interveiwersData = action.payload;
          state.interviewerListData = interveiwersData;
        }
      })
      .addCase(getAllInterviewersList.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.interviewerListData = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAllTechsList.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getAllTechsList.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let techsData = action.payload;
          state.techListData = techsData;
        }
      })
      .addCase(getAllTechsList.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.techListData = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAllTagsList.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getAllTagsList.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          let tagsData = action.payload;
          state.tagListData = tagsData;
        }
      })
      .addCase(getAllTagsList.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.tagListData = [];
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(resendInviteEmail.pending, (state: any) => {
        state.loadingUserData = true;
      })
      .addCase(resendInviteEmail.fulfilled, (state: any, action: any) => {
        state.loadingUserData = false;
        state.selectedUserData = {
          ...state.selectedUserData,
          invitationSent: true
        };
        state.successMessage = action?.payload?.message;
      })
      .addCase(resendInviteEmail.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveAttachedJd.pending, (state: UserManagementState) => {
        state.isUserListUpdated = false;
      })
      .addCase(
        saveAttachedJd.fulfilled,
        (state: UserManagementState, action) => {
          state.isUserListUpdated = false;
          state.successMessage = action.payload.message;
        }
      )
      .addCase(
        saveAttachedJd.rejected,
        (state: UserManagementState, action: any) => {
          state.isUserListUpdated = false;
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      .addCase(getAttachedJd.pending, (state: UserManagementState) => {
        state.isUserListUpdated = true;
      })
      .addCase(
        getAttachedJd.fulfilled,
        (state: UserManagementState, action) => {
          state.isUserListUpdated = false;
          if (action.payload) {
            let attachedJd = action.payload.data;
            state.selectedUserData.attachedJds = attachedJd;
          }
        }
      )
      .addCase(
        getAttachedJd.rejected,
        (state: UserManagementState, action: any) => {
          state.isUserListUpdated = false;
          state.isError = true;
          state.errorMessage = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      )
      //////////
      .addCase(addUserNotes.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addUserNotes.fulfilled, (state: any, action) => {
        state.loadingUserData = false;

        if (action.payload) {
          state.successMessage = action.payload.comment;
        }
      })
      .addCase(addUserNotes.rejected, (state: any, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserNotes.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUserNotes.fulfilled, (state: any, action) => {
        state.loadingUserData = false;

        if (action.payload) {
          let notes = action.payload.data;
          state.selectedUserData.notesData = notes;
        }
      })
      .addCase(getUserNotes.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.comments
          ? action?.payload?.comments
          : '';
      })
      .addCase(updateUserNotes.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateUserNotes.fulfilled, (state, action: any) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.comments;
      })
      .addCase(updateUserNotes.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = action?.payload?.comments
          ? action?.payload?.comments
          : '';
      })
      .addCase(deleteUserNotes.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteUserNotes.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.successMessage = action.payload.comments;
      })
      .addCase(deleteUserNotes.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.comments
          ? action?.payload?.comments
          : '';
      });
  }
});

export const {
  setIsUserListUpdated,
  setIsUserBasicInformationSaved,
  setIsError,
  setSelectedUserData,
  setSuccessMessageEmpty,
  setSelectedUserDetailsEmpty,
  setPreviousScreen
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
