import moment from 'moment';
import { Pagination } from 'src/core/model/pagination.dto';
import { DocumentType } from 'src/shared/models';
import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut,
  ApiResponse
} from 'src/shared/utils/api-request';

export const filterDataApi = async () => {
  const res = await apiGet(`job-description/filters`);
  return res.data;
};

export const addCompanyMasterDataAPI = async (payload) => {
  const res = await apiPost(`company/master-data`, payload);
  return res.data;
};

export const uploadFileAPI = async (formData: FormData) => {
  const res = await apiPost(`document/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const saveJobDetailsAPI = async (payload) => {
  const res = await apiPost(`/job-description`, payload);
  return res.data;
};

export const getJdListAPI = async (payload: {
  paginationData: Pagination;
  filter?: string;
}) => {
  const res = await apiGet(
    `/job-description?${payload.filter ? `${payload.filter}&` : ''}page=${
      payload.paginationData.page
    }&limit=${payload.paginationData.limit}${
      payload.paginationData.searchText
        ? `&search=${payload.paginationData.searchText}`
        : ''
    }`
  );
  return res.data;
};

export const editJdRecordAPI = async (payload) => {
  const res = await apiPut(`/job-description/${payload?.id}`, payload);
  return res.data;
};

export const updateJdStatusAPI = async (payload, jdId) => {
  const res = await apiPatch(`/job-description/${jdId}`, payload);
  return res.data;
};

export const deleteJdRecordAPI = async (payload) => {
  const res = await apiDelete(`job-description/${payload}`);
  return res.data;
};

export const getDocByDocId = async (
  docId: string
): Promise<ApiResponse<DocumentType>> => {
  const res = await apiGet(`/document/detail/${docId}`);
  return res.data;
};

export const getUsersDataAPI = async (payload: Pagination, role: string) => {
  const res = await apiGet(
    `/user?role=${role}&limit=${payload.limit}&active=${
      payload.activeInLast ? payload.activeInLast : ''
    }&page=${payload.page}${getSearchText(payload)}`
  );
  return res.data;
};

export const getPickCandidatesListAPI = async (
  payload: Pagination,
  selectedJdId: string
) => {
  const res = await apiGet(
    `/job-description/${selectedJdId}/pick-candidates?limit=${payload.limit}&page=${payload.page}`
  );
  return res.data;
};

export const getInterviewerListByFilterAPI = async (payload: any) => {
  const res = await apiGet(
    `/user/available/interviewer?searchText=${
      payload?.filters?.searchText
    }&date=${moment(payload?.filters?.date).format('YYYY-MM-DD')}&startTime=${
      payload?.filters?.startTime
    }&endTime=${payload?.filters?.endTime}`
  );
  return res.data;
};

export const scheduleInterviewAPI = async (payload: any) => {
  const res = await apiPost(`/interview`, payload);
  return res.data;
};

export const getSubmittedCandidatesListAPI = async (
  payload: any,
  selectedJdId: string
) => {
  const res = await apiGet(
    `/job-description/${selectedJdId}/submitted-candidates?limit=${payload.limit}&page=${payload.page}`
  );
  return res.data;
};

export const savePickedCandidateAPI = async (payload: any) => {
  const res = await apiPost(
    `/job-description/${payload.jdId}/pick-candidates`,
    payload
  );
  return res.data;
};

export function getSearchText(payload: Pagination) {
  if (!payload || !payload.searchText) {
    return '';
  }
  return `&search=${payload.searchText}`;
}

export const saveAssignTagsAPI = async (payload, jdId) => {
  const res = await apiPost(`/job-description/${jdId}/assign-user`, payload);
  return res.data;
};

export const getAssignTagsAPI = async (jdId: string) => {
  const res = await apiGet(`/job-description/${jdId}/assign-user`);
  return res.data;
};

export const attachJdToCandidateAPI = async (payload) => {
  const res = await apiPost(
    `/job-description/${payload?.jdId}/attach-jd-candidate`,
    payload?.data
  );
  return res.data;
};

export const deleteAssignedTagAPI = async (payload) => {
  const res = await apiDelete(`job-description/remove-user/${payload}`);
  return res.data;
};

function createDocumentUrl(document): string {
  const blob = new Blob([document.data]);
  return URL.createObjectURL(blob);
}
export const downloadDocumentAPI = async (documentId: string) => {
  const res = await apiGet(`document/download/${documentId}`, {
    responseType: 'blob'
  });
  return createDocumentUrl(res);
};

export const updateJdDocumentDetailsAPI = async (documentData) => {
  const res = await apiPut(
    `/document/${documentData.id}`,
    documentData.docDetails
  );
  return res.data;
};

export const checkResumeAttachAPI = async (payload) => {
  const res = await apiPost(
    `/job-description/${payload.id}/check-resume-attach`,
    payload
  );
  return res.data;
};

export const selectResumeAndApplyToJDAPI = async (payload) => {
  const res = await apiPost(
    `/job-description/${payload.jdId}/attach-resume`,
    payload.data
  );
  return res.data;
};

export const saveAssignedTemplatesAPI = async (payload: {assignedTemplateIds: string[], jdId: string}) => {
  const res = await apiPost(
    `/job-description/${payload.jdId}/assign-questions`,
    payload
  );
  return res.data;
}
