import { Pagination } from '../../../core/model/pagination.dto';
import { DocumentViewerModal } from '../../../shared/components/document-viewer/modal';
import {
  ApiResponse,
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';
import { UserData } from '../models';

export const getUserDataAPI = async (
  userId: string
): Promise<ApiResponse<UserData>> => {
  const res = await apiGet(`/user/${userId}`);
  return res.data;
};

export const addUserDataAPI = async (payload: any) => {
  const res = await apiPost(`/user?role=${payload.role}`, payload);
  return res.data;
};

export const updateUserDataAPI = async (payload: any) => {
  const res = await apiPut(`/user/${payload.id}?role=${payload.role}`, payload);
  return res.data;
};

export const toggleCandidateStatusAPI = async (id: string) => {
  const res = await apiPut(`/user/${id}/status`);
  return res.data;
};

export const getUsersDataAPI = async (payload: Pagination, role: string) => {
  const res = await apiGet(
    `/user?role=${role}&limit=${payload.limit}&active=${
      payload.activeInLast ? payload.activeInLast : ''
    }&status=${payload.status ? payload.status : ''}&page=${
      payload.page
    }${getSearchText(payload)}`
  );
  return res.data;
};

export const deleteTenantUserAPI = async (payload: any) => {
  const res = await apiDelete(`/tenant/user/${payload.email}`, payload);
  return res.data;
};

export const addTenantUserAPI = async (payload: any) => {
  const res = await apiPost(`/tenant/${payload.tenantId}/user`, {
    email: payload.email,
    name: payload.name,
    role: payload.role
  });
  return res.data;
};

export const updateTenantUserAPI = async (userData: any) => {
  const res = await apiPut(
    `/tenant/${userData.tenantId}/user/${userData.email}`,
    { roleId: userData.role }
  );
  return res.data;
};

export const updateTenantUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(
    `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
    { value: userData.value }
  );
  return res.data;
};

export const saveUserEducationDetails = async (payload: any) => {
  const res = await apiPost(`/user/${payload.userId}/education`, payload);
  return res.data;
};

export const getUserEducation = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/education`);
  return res.data;
};

export const editUserEducation = async (payload) => {
  const res = await apiPut(`user/education/${payload.id}`, payload);
  return res.data;
};

export const deleteUserEducation = async (payload: any) => {
  const res = await apiDelete(`user/education/${payload}`);
  return res.data;
};

export const saveUserWorkExperienceDetails = async (payload: any) => {
  const res = await apiPost(`/user/${payload.userId}/work-experience`, payload);
  return res.data;
};
export const getUserWorkExperience = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/work-experience`);
  return res.data;
};
export const editUserWorkExperience = async (payload) => {
  const res = await apiPut(`user/work-experience/${payload.id}`, payload);
  return res.data;
};
export const deleteWorkExperience = async (payload: any) => {
  const res = await apiDelete(`user/work-experience/${payload}`);
  return res.data;
};

export const saveUserAdditionalInfoAPI = async (payload: any) => {
  const res = await apiPut(`/user/${payload.id}/additional-info`, payload);
  return res.data;
};

export const saveUserCertificates = async (payload: any) => {
  const res = await apiPost(`/user/${payload.userId}/certificate`, payload);
  return res.data;
};

export const getUserCertificates = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/certificate`);
  return res.data;
};

export const editUserCertificates = async (payload) => {
  const res = await apiPut(`user/certificate/${payload.id}`, payload);
  return res.data;
};

export const deleteUserCertificates = async (payload: any) => {
  const res = await apiDelete(`user/certificate/${payload}`);
  return res.data;
};

export const saveUserSkills = async (payload: any) => {
  const res = await apiPost(`/user/${payload.id}/skill`, payload);
  return res.data;
};

export const getUserSkills = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/skills`);
  return res.data;
};

export const editUserSkills = async (payload) => {
  const res = await apiPut(`user/skill/${payload.id}`, payload);
  return res.data;
};

export const deleteUserSkills = async (payload: any) => {
  const res = await apiDelete(`user/skill/${payload}`);
  return res.data;
};

export const saveLinkAccountAPI = async (payload) => {
  const res = await apiPost(`/user/${payload.id}/account`, payload);
  return res.data;
};

export const getLinkAccountsAPI = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/accounts`);
  return res.data;
};

export const deleteLinkAccountAPI = async (payload) => {
  const res = await apiDelete(`/user/account/${payload.userId}`, payload);
  return res.data;
};

export const uploadFileAPI = async (formData: FormData) => {
  const res = await apiPost(`document/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const updateUserProfilePicAPI = async (payload) => {
  const res = await apiPatch(`/user/${payload.userId}/document`, payload);
  return res.data;
};

export const updateUserDocumentDetailsAPI = async (documentData) => {
  const res = await apiPut(
    `/document/${documentData.id}`,
    documentData.docDetails
  );
  return res.data;
};

export const downloadDocumentAPI = async (documentId: string) => {
  const res = await apiGet(`document/download/${documentId}`, {
    responseType: 'blob'
  });
  return createDocumentUrl(res);
};

export const getUploadedUserDocuments = async (userId) => {
  const res = await apiGet(`/user/${userId}/documents`);
  return res.data;
};

export const getDoucmentsByCategory = async (
  userId: string,
  category: string
) => {
  const res = await apiGet(`/user/${userId}/documents/${category}`);
  return res.data;
};

export const deleteDocumentsAPI = async (documentId: string) => {
  const res = await apiDelete(`/document/${documentId}`);
  return res.data;
};

export function getSearchText(payload: Pagination) {
  if (!payload || !payload.searchText) {
    return '';
  }
  return `&search=${payload.searchText}`;
}

function createDocumentUrl(document): string {
  const blob = new Blob([document.data]);
  return URL.createObjectURL(blob);
}

export const fileReader = async (documentId): Promise<any> => {
  const res = await apiGet(`document/download/${documentId}`, {
    responseType: 'blob'
  });
  const blob = new Blob([res.data], { type: res?.headers['content-type'] });

  const contentDisposition = res.headers['content-disposition'];
  let fileName = 'unknown';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
  }
  const blobUrl = URL.createObjectURL(blob);
  return {
    uri: blobUrl,
    fileType: res?.headers['content-type']
  } as DocumentViewerModal;
};

export const resendInviteEmailAPI = async (payload: UserData) => {
  const res = await apiPost(`/user/${payload.id}/resend-invite`, payload);
  return res.data;
};

export const saveAttachedJdAPI = async (payload, jdId) => {
  const res = await apiPost(`/job-description/${jdId}/assign-user`, payload);
  return res.data;
};

export const getAttachedJdAPI = async (candidateId: string) => {
  const res = await apiGet(`/job-description/attached-jd/${candidateId}`);
  return res.data;
};

export const saveUserNotes = async (payload: any) => {
  const res = await apiPost(`/user/${payload.userId}/notes`, payload);
  return res.data;
};
export const getUserNotesDetails = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/notes`);
  return res.data;
};
export const updateUserNotesDetails = async (payload) => {
  const res = await apiPut(`user/notes/${payload.id}`, {
    comments: payload.comments
  });
  return res.data;
};
export const deleteUserNotesDetails = async (payload: any) => {
  const res = await apiDelete(`user/notes/${payload}`);
  return res.data;
};
