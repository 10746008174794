import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';
import { TemplateType } from '../models';
import { Pagination } from 'src/core/model/pagination.dto';

export const getQuestionsListAPI = async (
  payload: Pagination,
  templateId: string | null = null,
  jdId: string | null = null
) => {
  const res = await apiGet(
    `/master-data/question-bank?limit=${payload.limit}&page=${
      payload.page
    }&search=${payload?.searchText}${
      templateId ? `&templateId=${templateId}` : ''
    }${jdId ? `&jdId=${jdId}` : ''}`
  );
  return res.data;
};

export const getCandidateStatusAPI = async () => {
  const res = await apiGet(`/master-data/candidate-status`);
  return res.data;
};

export const saveQuestionAPI = async (payload: any) => {
  const res = await apiPost(`/master-data/question-bank`, payload);
  return res.data;
};

export const editQuestionAPI = async (payload: any) => {
  const res = await apiPut(`/master-data/question/${payload?.id}`, payload);
  return res.data;
};

export const deleteQuestionByIdAPI = async (payload: any) => {
  const res = await apiDelete(`/master-data/question/${payload?.id}`, payload);
  return res.data;
};

export const bulkQuestionDeleteAPI = async (payload: any) => {
  const res = await apiDelete(`/master-data/bulk-question-delete/`, payload);
  return res.data;
};

export const getAllTemplatesAPI = async (
  payload: { search?: string } = { search: '' }
) => {
  const res = await apiGet(
    `/master-data/question-template?search=${payload?.search}`
  );
  return res.data;
};

export const getTemplateDataByIdAPI = async (payload: {
  id: string;
  searchText?: string;
  typeOfOperation?: string;
}) => {
  const res = await apiGet(
    `/master-data/question-template/${payload?.id}?search=${
      payload?.searchText ? payload?.searchText : ''
    }&typeOfOperation=${
      payload?.typeOfOperation ? payload?.typeOfOperation : ''
    }`
  );
  return res.data;
};

export const createNewTemplateAPI = async (payload: any) => {
  const res = await apiPost(`/master-data/question-template`, payload);
  return res.data;
};

export const editTemplateTitleAPI = async (payload: TemplateType) => {
  const res = await apiPatch(
    `/master-data/question-template/${payload?.id}`,
    payload
  );
  return res.data;
};

export const deleteTemplateAPI = async (payload: TemplateType) => {
  const res = await apiDelete(
    `/master-data/question-template/${payload?.id}`,
    payload
  );
  return res.data;
};
