import { UserData } from 'src/modules/user-management/models';
import {
  ViewByDropDownType,
  ActiveInLastDropDownType,
  DashboardDropDownType
} from '../models';
import { QuestionBankActionDropdownType } from 'src/modules/MasterData/models';

export const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
export const REACT_APP_LOCAL_HOST = process.env.REACT_APP_LOCAL_HOST;
export const REACT_APP_DAILYCO_ROOM_START_BEFORE = Number(
  process.env.REACT_APP_DAILYCO_ROOM_START_BEFORE
);
export const DEV_ENVIRONMENT = Boolean(process.env.REACT_APP_DEV_ENVIRONMENT);
export var API_ACCESS_TOKEN = '';
export const TABLE_PAGESIZE = [10, 25, 50, 100];

export const AUTH_CONFIG = {
  LOGIN_TYPE_GOOGLE: 'goog',
  LOGIN_TYPE_MICROSOFT: 'msft',
  LOGIN_TYPE_BASIC_AUTH: 'basic-auth',
  LOGIN_TYPE_ADMIN: `admin`,
  LOGIN_TYPE_USER: `user`,
  LOGIN_TYPE_CANDIDATE: `candidate`,
  LOGIN_TYPE_INTERVIEWER: `interviewer`,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

export const ROLES = {
  SUPER_ADMIN: 'platform-admin',
  ADMIN_OPERATOR: 'platform-coordinator',
  COMPANY_TECH: 'company-tech',
  TENANT_OPERATOR: 'company-tag',
  TENANT_ADMIN: 'company-admin',
  CANDIDATE: 'candidate',
  INTERVIEWER: 'interviewer'
};

export const DEMO_EMAILS = {
  PLATFORM_ADMIN: 'platform.admin@indexnine.com',
  COMPANY_TECH: 'tech@indexnine.com',
  COMPANY_ADMIN: 'company.admin@indexnine.com',
  CANDIDATE: 'candidate@indexnine.com',
  INTERVIEWER: 'interviewer@indexnine.com',
  COMPANY_TAG: 'company.tag@indexnine.com',
  MAULIK_KAKADIYA: 'maulik.kakadiya+ca@indexnine.com'
};

export const USER_TYPE = {
  TENANT_USER: 't-u',
  ADMIN_USER: 'a-u'
};

export const ADMIN_ROLES = [
  {
    roleName: 'Platform Admin',
    id: 'platform-admin'
  },
  {
    roleName: 'Platform Operator',
    id: 'platform-coordinator'
  }
];

export const TENANT_ROLES = [
  {
    roleName: 'Company Admin',
    id: 'company-admin'
  },
  {
    roleName: 'Company Tag',
    id: 'company-tag'
  },
  {
    roleName: 'Company Tech',
    id: 'company-tech'
  },
  {
    roleName: 'Candidate',
    id: 'candidate'
  },
  {
    roleName: 'Interviewer',
    id: 'interviewer'
  }
];

export const getRole = (roleId) => {
  const roleList = [...ADMIN_ROLES, ...TENANT_ROLES];
  const filter = roleList.filter((role) => {
    return roleId === role.id;
  });
  if (filter.length > 0) {
    return filter[0].roleName;
  }
};

export const REFRESH_TOKEN = `REFRESH_TOKEN `;
export const DECODED_TOKEN = `DECODED_TOKEN `;
export const ACCESS_TOKEN = `ACCESS_TOKEN `;
export const CHAT_BOT_API_TOKEN = `chatBotApiToken`;

export const LIST_VIEW = {
  TABLE_VIEW: 'Table',
  CARD_VIEW: 'Card'
};

export const ASSET_TYPE = {
  SSH: 'SSH'
};
export const USER_TABLE = 'UserTable';
export const INVALID_OTP = 'Invalid OTP';

export const I18N = {
  DEFAULT: 'lang',
  ADMIN_USER: 'adminusermanagement',
  ORGANIZATION: 'organization',
  USER_MANAGEMENT: 'usermanagement',
  ALL: 'all',
  SIDE_BAR_MENU: 'sidebarmenu',
  LOGIN: 'Login'
};

export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  MOBILE_NO: /^\+?[0-9]{8,16}$/,
  NUMBER_DECIMAL:
    /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  NUMBER_INTEGER: /^(?:\d*[1-9]\d*|)$/,
  NUMBER_ONLY: /^\d+(\.\d+)?$/,
  TEXT_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/,
  ALPHA_NUMBERICS: /^[a-zA-Z0-9-_ ]*$/,
  JSON: /^[\],:{}\s]*$|^"(.|\\[\\"/bfnrt])*"$/,
  URL: /^(https?:\/\/)?(www\.)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*(\?.*)?(#.*)?$/,
  URL_LINK:
    /^(?:https?|www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+)(?::\d+)?(?:\/(?:[^/]+))*$/,
  DATE: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
};

export const FIELD_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  EMAIL: 'email',
  SWITCH: 'switch',
  SELECT: 'select',
  REGEX: 'regex',
  FREE_TEXT: 'freetext',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  NUMBER_ONLY: 'number_only',
  INTEGER_ONLY: 'integer_only',
  ALPHA_NUMBERICS: 'alphanumerics',
  MULTI_SELECT: 'multi-select',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX_GROUP: 'checkbox_group',
  DATE: 'date',
  UPLOAD_BUTTON: 'upload_button',
  URL: 'url',
  NUMBER_INTEGER: 'number_integer',
  TEXT_EDITOR: 'text_editor',
  MULTI_SELECT_ADD_OPTION: 'multi-select-add-option'
};

//below const is from seed, we can remove it if it is not break any functionality

export const HEADER_AUTHORIZATION = 'a64bccc5-1648-46ae-ad78-b0f890f1d6c1';

export const DEVSTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const QASTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const CLINICALUSER_STAGE_USERPOOL_ID = 'us-west-2_VU5TDSRwJ';

export const DEVSTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const QASTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const CLINICALUSER__USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const LOGIN = import('src/auth/login/Login');
export const STYLE_GUIDE = import('src/modules/StyleGuide/StyleGuide');
export const ADMIN_LOGIN = import('src/auth/login/Login');

export const USER_MANAGEMENT = import(
  'src/modules/user-management/UserManagement'
);
export const WELCOME = import('src/modules/Welcome/Welcome');
export const PROFILE = import('src/modules/Profile/Profile');
export const INTERVIEWS = import('src/modules/Interviews/Interviews');
export const MANAGE_SLOTS = import('src/modules/ManageSlots/ManageSlots');
export const DASHBOARD = import('src/modules/Dashboard/Dashboard');

export const ADMIN_OPERATOR_USER_ASSETS = import(
  'src/modules/user-management/UserManagement'
);
export const CHECK_YOUR_EMAIL = import('src/auth/login/CheckYourEmail');
export const JD = import('src/modules/JD/Jd');
export const VIEW_JD_DETAILS = import(
  'src/modules/JD/components/JdViewDetailsLayout'
);
export const JD_PICK_CANDIDATE = import(
  'src/modules/JD/components/PickCandidate'
);
export const PLAYGROUND = import('src/modules/Playground/Playground');
export const INTERVIEW_CALL = import('src/modules/InterviewCall/InterviewCall');
export const MASTER_DATA = import('src/modules/MasterData/MasterData');
export const ERROR_PAGE = import('src/modules/ErrorPage/ErrorPage');

export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress'
}

export enum CandidateStatusInternal {
  INTERVIEW_SCHEDULED = 'Interview Scheduled',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  HIRED = 'Hired'
}

export const CandidateStatusInternalMapping: Record<
  string,
  CandidateStatusInternal
> = {
  interview_scheduled: CandidateStatusInternal.INTERVIEW_SCHEDULED,
  under_review: CandidateStatusInternal.UNDER_REVIEW,
  rejected: CandidateStatusInternal.REJECTED,
  hired: CandidateStatusInternal.HIRED
};

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

export const MEGABYTE_CONVERTER = 1000000;
export const MESSAGE = 'message';
export const STATUS_404 = '404';

export const accessTokenFunction = (token?: any) => {
  API_ACCESS_TOKEN = token;
};

export const USER_TAB_NUMBER = {
  BASIC_INFO: 0,
  ADDITIONAL_INFO: 1,
  WORK_EXP: 2,
  EDUCATION: 3,
  CERTIFICATION: 4,
  SKILL: 5,
  LINK_ACCOUNT: 6,
  DOCUMENT: 7,
  ATTACH_JD: 8,
  INTERVIEWS: 9
};
export const workAuthorizationOptions = [
  { label: 'H1-B', id: 'H1-B' },
  { label: 'Green Card', id: 'Green Card' },
  { label: 'US citizen', id: 'US citizen' },
  { label: 'L1', id: 'L1' }
];

export const rateTypeOptions = [
  { label: 'W2', id: 'W2' },
  { label: 'C2C', id: 'C2C' },
  { label: '1099', id: '1099' }
];

export const rateSkillOptions = [
  { id: 'Never Worked', label: 'Never Worked' },
  { id: 'Basic Understanding', label: 'Basic Understanding' },
  { id: 'Intermediate', label: 'Intermediate' },
  { id: 'Good', label: 'Good' },
  { id: 'Excellent', label: 'Excellent' }
];

export const ACTIVE_IN_LAST_OPTION: ActiveInLastDropDownType[] = [
  { id: 'LAST_WEEK', label: 'Last Week', days: 7, isSelfChange: true },
  { id: 'LAST_MONTH', label: 'Last Month', days: 30, isSelfChange: true },
  { id: 'LAST_YEAR', label: 'Last Year', days: 365, isSelfChange: true },
  { id: 'ALL', label: 'All', days: null, isSelfChange: true }
];

export const VIEW_BY_VALUE_OPTION: ViewByDropDownType[] = [
  { id: 'ENABLE', label: 'Enable', isSelfChange: true },
  { id: 'DISABLE', label: 'Disable', isSelfChange: true },
  { id: 'PENDING', label: 'Pending', isSelfChange: true },
  { id: null, label: 'All', isSelfChange: true }
];

export const DASHBOARD_ACTIVE_OPTION: DashboardDropDownType[] = [
  { id: 'ACTIVE_CANDIDATE', label: 'Active Candidates', isSelfChange: true },
  { id: 'ACTIVE_JDS', label: 'Active JDs', isSelfChange: true }
];

export const DASHBOARD_INTERNAL_EXTERNAL_OPTION: DashboardDropDownType[] = [
  { id: 'ONLY_INTERNAL', label: 'Only Internal', isSelfChange: true },
  { id: 'ONLY_EXTERNAL', label: 'Only External', isSelfChange: true },
  { id: 'BOTH', label: 'Both', isSelfChange: true }
];
export const DOCUMENT_CATEGORY = {
  default: 'DEFAULT',
  profilePic: 'PROFILE_PIC',
  candidateResume: 'CANDIDATE_RESUME',
  companyLogo: 'COMPANY_LOGO',
  canidateWorkAuthorizationDoc: 'CANDIDATE_WORK_AUTH_DOC',
  jd: 'JD'
};

export const selectedUserDataInitialState: UserData = {
  profilePicDoc: '',
  resumeDoc: '',
  emailId: '',
  tenantId: '',
  id: '',
  name: '',
  contactNumber: '',
  address: '',
  city: '',
  zipCode: '',
  workAuthorization: '',
  workAuthDoc: '',
  employerName: '',
  rateType: '',
  rate: '',
  invitationSent: false,
  workExperienceListData: [],
  educationListData: [],
  certificateListData: [],
  userSkillsData: [],
  linkAccountList: [],
  userDocumentListData: [],
  attachedJds: [],
  notesData: []
};

export const USER_ROLES = {
  company: 'company',
  candidate: 'candidate',
  interviewer: 'interviewer',
  tag: 'company-tag',
  tech: 'company-tech'
};

export const FILTER_CATEGORY_IDS = new Map([
  ['POSTED', 'posted'],
  ['EXPERIENCE_LEVEL', 'experienceLevel'],
  ['JOB_TYPE', 'jobType'],
  ['JOB_STATUS', 'jobStatus'],
  ['SKILLS', 'skills'],
  ['ASSOCIATED_PARTY', 'associatedParties']
]);

export const userInterviewStatus = {
  ongoing: 'ongoing',
  confirmed: 'confirmed'
};

export const jdTabNumbers = {
  jobDetails: 0,
  assignTag: 1,
  assignQuestios: 2
};

export const JD_DETAILS_TAB_NUMBERS = {
  jobDetails: 0,
  assignedTag: 1,
  submittedCandidate: 2
};

export function transformFiltersByCategory(data, category) {
  const categoryData = data?.find((item) => item?.category === category);
  if (!categoryData) return [];

  return categoryData.filter.map((filter) => ({
    id: filter.id,
    label: filter.name
  }));
}

export const JOB_DESCRIPTION_FILTER = {
  EXPERIENCE_LEVEL: 'EXPERIENCE_LEVEL',
  POSTED: 'POSTED',
  JOB_TYPE: 'JOB_TYPE',
  JOB_STATUS: 'JOB_STATUS',
  SKILLS: 'SKILLS',
  ASSOCIATED_PARTY: 'ASSOCIATED_PARTY'
};

export const TIME_RANGE_OPTIONS = [
  { id: '/hour', label: 'per hour' },
  { id: '/day', label: 'per day' },
  { id: '/week', label: 'per week' },
  { id: '/month', label: 'per month' },
  { id: '/year', label: 'per year' }
];

export const JOB_LOCATION_TYPE_OPTIONS = [
  { id: 'Remote', label: 'Remote' },
  { id: 'On Site', label: 'On Site' },
  { id: 'Hybrid', label: 'Hybrid' }
];

export const JOB_NATURE_OPTIONS = [
  { id: 'Contract', label: 'Contract' },
  { id: 'Full Time', label: 'Full Time' },
  { id: 'Part Time', label: 'Part Time' },
  { id: 'Internship', label: 'Internship' }
];

export const MULTI_SELECT_ICONS = {
  rightArrow: 'icon-arrow'
};

export const getTheNumberFromString = (data) => {
  return parseFloat(data?.match(/\d+/));
};

export const getUnitsFromString = (data) => {
  return data?.match(/\/\w+/);
};

export const CONSTANTS_TEXT = {
  open: 'open',
  close: 'close',
  all: 'ALL',
  view: 'view',
  skills: 'skills',
  associatedParties: 'associatedParties',
  createJd: 'createJd',
  jobDescription: 'jobDescription'
};

export const CONSTANT_FILTER_CATEGORIES = {
  POSTED: 'POSTED',
  EXPERIENCE_LEVEL: 'EXPERIENCE_LEVEL',
  JOB_TYPE: 'JOB_TYPE',
  JOB_STATUS: 'JOB_STATUS',
  SKILLS: 'SKILLS'
};

export const INTERVIEW_LIST_TAB_NUMBERS = {
  0: 'upcoming',
  1: 'completed',
  2: 'pending'
};

export const INTERVIEW_ACCEPTANCE_STATUS = {
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED'
};

export const QUESTION_BANK_ACTIONS: QuestionBankActionDropdownType[] = [
  { id: 'CREATE_TEMPLATE', label: 'Create Template', isSelfChange: true },
  { id: 'DELETE', label: 'Delete', isSelfChange: true }
];

export const QUESTION_BANK_ADD_QUESTIONS: QuestionBankActionDropdownType[] = [
  { id: 'ADD_MANUALLY', label: 'Add Manually', isSelfChange: true }
];

export const QUESTION_BANK_ADD_QUESTIONS_FROM_TEMPLATE: QuestionBankActionDropdownType[] =
  [{ id: 'ADD_MANUALLY', label: 'Add Questions', isSelfChange: true }];

export const TYPE_OF_OPERATION = {
  DELETE: 'DELETE'
};

export const QUESTION_BANK_TABS = {
  QUESTIONS: 'Questions',
  TEMPLATES: 'Templates'
};

export const PAYMENT_TYPE_OPTIONS = [
  { id: 'Corp to Corp', label: 'Corp to Corp' }
];

export const PAYMENT_TERMS_OPTIONS = [{ id: 'N-15', label: 'N-15' }];

export const USER_JD_STATUS = [
  { id: 'APPLIED', label: 'Applied', color: 'warning' },
  { id: 'SHORTLISTED', label: 'Shortlisted', color: 'error' },
  { id: 'UPLOADED', label: 'Uploaded', color: 'warning' }
];

export const LABELS = {
  1: 'Basic',
  2: 'Intermediate',
  3: 'Advance',
  4: 'Expert',
  5: '5 Star'
};

export const Details = {
  id: '99b8ebd7-3ba2-45a2-846e-ca1839b9b710',
  comments:
    'Hi @Maulik Kakadiya,  I have raised a PR for the frontend for Candidate Rate when CA/TAG submits the profile of candidate, Once you get a movement, could you please review and merge it.',
  transcript: 'string',
  notes: 'New one',
  startTime: '2024-05-09T19:00:00.000Z',
  endTime: '2024-05-09T19:30:00.000Z',
  candidateId: 'fc173819-9349-4490-aaac-ac43254876f7',
  candidateName: 'Rhy Candidate',
  interviewerId: 'c6534c6d-b973-4205-be7d-dcd853b7f049',
  interviewerName: 'Rhythm Interviewer',
  jdId: '8dc32810-7536-449b-a46d-f0f248fc9052',
  companyName: 'I9',
  interviewId: '9a2b1878-24d3-41a7-b040-77f3dc3795ac',
  resumeName: 'john_doe_resume.pdf',
  resumeId: 'e977f771-4ae4-4db3-b0fd-df38b88885de',
  interviewerRating: 1,
  candidateRating: 1,
  interviewUrl: 'string',
  photoId: 'e977f771-4ae4-4db3-b0fd-df38b88885de',
  interviewScreenshot: 'e977f771-4ae4-4db3-b0fd-df38b88885de',
  candidateEmail: 'rhy8981+1@gmail.com',
  candidateContactNumber: '91890222222',
  interviewerEmail: 'rhy8981@gmail.com',
  interviewerContactNumber: '8902348903',
  jdTitle: 'Java Developer'
};

export const ActiveCandidatesDemoData = [
  {
    id: 1,
    name: 'Alice Johnson',
    emailId: 'alice.johnson@example.com',
    contactNumber: '+1234567890',
    tagName: 'Engineering',
    jobTitle: 'Software Developer',
    catelog: 'Technical',
    date: '2024-08-01',
    time: '10:00 AM',
    status: 'Active'
  },
  {
    id: 2,
    name: 'Bob Smith',
    emailId: 'bob.smith@example.com',
    contactNumber: '+1987654321',
    tagName: 'Product',
    jobTitle: 'Product Manager',
    catelog: 'Management',
    date: '2024-08-02',
    time: '11:00 AM',
    status: 'Active'
  },
  {
    id: 3,
    name: 'Charlie Brown',
    emailId: 'charlie.brown@example.com',
    contactNumber: '+1123456789',
    tagName: 'Design',
    jobTitle: 'UI/UX Designer',
    catelog: 'Design',
    date: '2024-08-03',
    time: '1:00 PM',
    status: 'Inactive'
  },
  {
    id: 4,
    name: 'David Wilson',
    emailId: 'david.wilson@example.com',
    contactNumber: '+1098765432',
    tagName: 'Engineering',
    jobTitle: 'Backend Developer',
    catelog: 'Technical',
    date: '2024-08-04',
    time: '2:00 PM',
    status: 'Active'
  },
  {
    id: 5,
    name: 'Eva Green',
    emailId: 'eva.green@example.com',
    contactNumber: '+1012345678',
    tagName: 'Marketing',
    jobTitle: 'Marketing Specialist',
    catelog: 'Marketing',
    date: '2024-08-05',
    time: '3:00 PM',
    status: 'Active'
  },
  {
    id: 6,
    name: 'Frank White',
    emailId: 'frank.white@example.com',
    contactNumber: '+1998765432',
    tagName: 'Sales',
    jobTitle: 'Sales Manager',
    catelog: 'Sales',
    date: '2024-08-06',
    time: '4:00 PM',
    status: 'Inactive'
  },
  {
    id: 7,
    name: 'Grace Hopper',
    emailId: 'grace.hopper@example.com',
    contactNumber: '+1112233445',
    tagName: 'Engineering',
    jobTitle: 'DevOps Engineer',
    catelog: 'Technical',
    date: '2024-08-07',
    time: '5:00 PM',
    status: 'Active'
  },
  {
    id: 8,
    name: 'Henry Ford',
    emailId: 'henry.ford@example.com',
    contactNumber: '+1223344556',
    tagName: 'Product',
    jobTitle: 'Product Designer',
    catelog: 'Design',
    date: '2024-08-08',
    time: '6:00 PM',
    status: 'Active'
  },
  {
    id: 9,
    name: 'Isabella Garcia',
    emailId: 'isabella.garcia@example.com',
    contactNumber: '+1334455667',
    tagName: 'HR',
    jobTitle: 'HR Manager',
    catelog: 'Management',
    date: '2024-08-09',
    time: '9:00 AM',
    status: 'Active'
  },
  {
    id: 10,
    name: 'Jack Ma',
    emailId: 'jack.ma@example.com',
    contactNumber: '+1445566778',
    tagName: 'Finance',
    jobTitle: 'Financial Analyst',
    catelog: 'Finance',
    date: '2024-08-10',
    time: '10:00 AM',
    status: 'Inactive'
  }
];

export const ActiveJdDemoData = [
  {
    id: 1,
    jdCode: 'JD001',
    jdName: 'Senior Software Engineer',
    activeProfile: 5,
    submitted: 10,
    scheduled: 4,
    selected: 1
  },
  {
    id: 2,
    jdCode: 'JD002',
    jdName: 'Product Manager',
    activeProfile: 3,
    submitted: 8,
    scheduled: 3,
    selected: 1
  },
  {
    id: 3,
    jdCode: 'JD003',
    jdName: 'Data Scientist',
    activeProfile: 7,
    submitted: 12,
    scheduled: 5,
    selected: 2
  },
  {
    id: 4,
    jdCode: 'JD004',
    jdName: 'UX Designer',
    activeProfile: 4,
    submitted: 9,
    scheduled: 2,
    selected: 0
  },
  {
    id: 5,
    jdCode: 'JD005',
    jdName: 'DevOps Engineer',
    activeProfile: 6,
    submitted: 11,
    scheduled: 6,
    selected: 3
  },
  {
    id: 6,
    jdCode: 'JD006',
    jdName: 'Marketing Specialist',
    activeProfile: 3,
    submitted: 7,
    scheduled: 2,
    selected: 1
  },
  {
    id: 7,
    jdCode: 'JD007',
    jdName: 'Business Analyst',
    activeProfile: 5,
    submitted: 10,
    scheduled: 4,
    selected: 2
  },
  {
    id: 8,
    jdCode: 'JD008',
    jdName: 'QA Engineer',
    activeProfile: 4,
    submitted: 9,
    scheduled: 3,
    selected: 1
  },
  {
    id: 9,
    jdCode: 'JD009',
    jdName: 'Network Engineer',
    activeProfile: 6,
    submitted: 11,
    scheduled: 5,
    selected: 3
  },
  {
    id: 10,
    jdCode: 'JD010',
    jdName: 'HR Manager',
    activeProfile: 3,
    submitted: 8,
    scheduled: 3,
    selected: 1
  }
];

export const DASHBOARD_DEMO_DATA = {
  LAST_WEEK: {
    activeJds: {
      value: 15,
      percentage: '+10.00%',
      totalText: 'Total - 20',
      newJDs: '+05 New JDs'
    },
    activeCandidates: {
      value: 35,
      percentage: '+15.00%',
      totalText: 'Total - 40',
      newJDs: '+05 New Candidates'
    },
    completedInterviews: {
      value: 60,
      percentage: '',
      totalText: 'Total - 200',
      newJDs: '40 - Scheduled'
    },
    ratings: [
      { title: 'Basic', value: 1, number: 8 },
      { title: 'Intermediate', value: 2, number: 18 },
      { title: 'Advance', value: 3, number: 25 },
      { title: 'Expert', value: 4, number: 50 },
      { title: '5Star', value: 5, number: 45 }
    ]
  },
  LAST_MONTH: {
    activeJds: {
      value: 20,
      percentage: '+18.01%',
      totalText: 'Total - 35',
      newJDs: '+05 New JDs'
    },
    activeCandidates: {
      value: 40,
      percentage: '+18.01%',
      totalText: 'Total - 35',
      newJDs: '+05 New Candidates'
    },
    completedInterviews: {
      value: 70,
      percentage: '',
      totalText: 'Total - 250',
      newJDs: '50 - Scheduled'
    },
    ratings: [
      { title: 'Basic', value: 1, number: 10 },
      { title: 'Intermediate', value: 2, number: 20 },
      { title: 'Advance', value: 3, number: 30 },
      { title: 'Expert', value: 4, number: 60 },
      { title: '5Star', value: 5, number: 50 }
    ]
  },
  LAST_YEAR: {
    activeJds: {
      value: 25,
      percentage: '+25.00%',
      totalText: 'Total - 45',
      newJDs: '+10 New JDs'
    },
    activeCandidates: {
      value: 50,
      percentage: '+20.00%',
      totalText: 'Total - 60',
      newJDs: '+10 New Candidates'
    },
    completedInterviews: {
      value: 80,
      percentage: '',
      totalText: 'Total - 300',
      newJDs: '60 - Scheduled'
    },
    ratings: [
      { title: 'Basic', value: 1, number: 12 },
      { title: 'Intermediate', value: 2, number: 22 },
      { title: 'Advance', value: 3, number: 35 },
      { title: 'Expert', value: 4, number: 70 },
      { title: '5Star', value: 5, number: 55 }
    ]
  },
  ALL: {
    activeJds: {
      value: 30,
      percentage: '+30.00%',
      totalText: 'Total - 50',
      newJDs: '+15 New JDs'
    },
    activeCandidates: {
      value: 60,
      percentage: '+25.00%',
      totalText: 'Total - 70',
      newJDs: '+15 New Candidates'
    },
    completedInterviews: {
      value: 90,
      percentage: '',
      totalText: 'Total - 350',
      newJDs: '70 - Scheduled'
    },
    ratings: [
      { title: 'Basic', value: 1, number: 15 },
      { title: 'Intermediate', value: 2, number: 25 },
      { title: 'Advance', value: 3, number: 40 },
      { title: 'Expert', value: 4, number: 80 },
      { title: '5Star', value: 5, number: 60 }
    ]
  }
};

export const DemoCompanyNames = [
  'Apple',
  'Google',
  'Microsoft',
  'Amazon',
  'Facebook',
  'Tesla',
  'Netflix',
  'Intel',
  'IBM',
  'Oracle'
];
