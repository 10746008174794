import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/providers/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderUserbox from './user-box/HeaderUserbox';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Typography } from '../../shared/components/index';
import { useTranslation } from 'react-i18next';
import { I18N } from 'src/shared/constants/constants';
import * as ROUTES from 'src/shared/constants/routes';
import { useLocation } from 'react-router';

const Header = () => {
  const { t } = useTranslation(I18N.ALL);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [title, setTitle] = useState('');
  const { pathname } = useLocation();
  const theme = useTheme();

  useEffect(() => {
    updateTitle(pathname);
  }, [pathname]);

  const updateTitle = (pathName: string) => {
    switch (pathName) {
      case ROUTES.USER_MANAGEMENT:
        setTitle('sidebar-menu.user_management');
        break;
      case ROUTES.DASHBOARD:
        setTitle('sidebar-menu.dashboard');
        break;
      case ROUTES.INTERVIEWS:
        setTitle('sidebar-menu.interviews');
        break;
      case ROUTES.MASTER_DATA:
        setTitle('sidebar-menu.master_data');
        break;
      case ROUTES.JD:
        setTitle('sidebar-menu.jds');
        break;
      case ROUTES.REPORTS:
        setTitle('sidebar-menu.reports');
        break;
      case ROUTES.MANAGE_SLOTS:
        setTitle('sidebar-menu.manage_slots');
        break;
      default:
        break;
    }
  };

  return (
    <Box
      className={
        theme.palette.mode === 'dark'
          ? 'HeaderWrapperDarkTheme HeaderWrapper'
          : 'HeaderWrapperLightTheme HeaderWrapper'
      }
      display="flex"
      alignItems="center"
    >
      <Box sx={{ ml: theme.sidebar.width }}>
        <Box sx={{ pl: 3 }} className="text-h3 tertiaryColor">
          {t(title)}
        </Box>
      </Box>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      ></Stack>
      <Box display="flex" alignItems="center">
        <Box className="mr-16">
          <span className="icon-notification"></span>
        </Box>
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2
          }}
          className="userbox"
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
