import { RootState } from 'src/store/reducer';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  bulkQuestionDeleteAPI,
  createNewTemplateAPI,
  deleteQuestionByIdAPI,
  deleteTemplateAPI,
  editQuestionAPI,
  editTemplateTitleAPI,
  getAllTemplatesAPI,
  getCandidateStatusAPI,
  getQuestionsListAPI,
  getTemplateDataByIdAPI,
  saveQuestionAPI
} from '../apis/masterDataAPI';
import { CandidateStatus, QuestionType, TemplateType } from '../models';
import {
  Pagination,
  PaginationResponseDto
} from 'src/core/model/pagination.dto';

export interface MasterDataState {
  isError: boolean;
  errorMessage: string;
  isSuccess: boolean;
  successMessage: string;
  questionList: PaginationResponseDto<QuestionType>;
  templatesList: TemplateType[];
  candidateStatus: CandidateStatus[];
  selectedTemplateData: TemplateType;
  activeBuestionBankTab: string;
}

const initialState: MasterDataState = {
  isError: false,
  errorMessage: '',
  isSuccess: false,
  successMessage: '',
  questionList: null,
  templatesList: null,
  candidateStatus: null,
  selectedTemplateData: null,
  activeBuestionBankTab: 'Questions'
};

export const getQuestionsList = createAsyncThunk(
  'masterData/getQuestionsList',
  async (
    payload: { pagination: Pagination; templateId?: string; jdId?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getQuestionsListAPI(
        payload.pagination,
        payload.templateId,
        payload.jdId
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCandidateStatus = createAsyncThunk(
  'masterData/getCandidateStatus',
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await getCandidateStatusAPI();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveQuestion = createAsyncThunk(
  'masterData/saveQuestion',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await saveQuestionAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editQuestion = createAsyncThunk(
  'masterData/editQuestion',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await editQuestionAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestionById = createAsyncThunk(
  'masterData/deleteQuestionById',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteQuestionByIdAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkQuestionDelete = createAsyncThunk(
  'masterData/bulkQuestionDelete',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await bulkQuestionDeleteAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTemplates = createAsyncThunk(
  'masterData/getAllTemplates',
  async (payload: { search?: string }, { rejectWithValue }) => {
    try {
      const response = await getAllTemplatesAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTemplateDataById = createAsyncThunk(
  'masterData/getTemplateDataById',
  async (
    payload: { id: string; searchText?: string; typeOfOperation?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getTemplateDataByIdAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewTemplate = createAsyncThunk(
  'masterData/createNewTemplate',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await createNewTemplateAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTemplateTitle = createAsyncThunk(
  'masterData/editTemplateTitle',
  async (payload: TemplateType, { rejectWithValue }) => {
    try {
      const response = await editTemplateTitleAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  'masterData/deleteTemplate',
  async (payload: TemplateType, { rejectWithValue }) => {
    try {
      const response = await deleteTemplateAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
      if (!action.payload) {
        state.successMessage = '';
      }
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setActiveQuestionBankTab: (state, action: PayloadAction<string>) => {
      state.activeBuestionBankTab = action?.payload;
    },
    setLocalTemplateData: (state, action: PayloadAction<TemplateType>) => {
      state.selectedTemplateData = action?.payload;
    },
    emptySelectedTemplateData: (state, action: PayloadAction<null>) => {
      state.selectedTemplateData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsList.pending, (state) => {})
      .addCase(getQuestionsList.fulfilled, (state, action) => {
        state.questionList = action?.payload?.data;
      })
      .addCase(getQuestionsList.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getCandidateStatus.pending, (state) => {})
      .addCase(getCandidateStatus.fulfilled, (state, action) => {
        state.candidateStatus = action?.payload?.data;
      })
      .addCase(getCandidateStatus.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveQuestion.pending, (state) => {})
      .addCase(saveQuestion.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
      })
      .addCase(saveQuestion.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editQuestion.pending, (state) => {})
      .addCase(editQuestion.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
        // set the edited question
      })
      .addCase(editQuestion.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteQuestionById.pending, (state) => {})
      .addCase(deleteQuestionById.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
      })
      .addCase(deleteQuestionById.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(bulkQuestionDelete.pending, (state) => {})
      .addCase(bulkQuestionDelete.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
      })
      .addCase(bulkQuestionDelete.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAllTemplates.pending, (state) => {})
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        if (action.payload) {
          state.templatesList = action?.payload?.data;
        }
      })
      .addCase(getAllTemplates.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getTemplateDataById.pending, (state) => {})
      .addCase(getTemplateDataById.fulfilled, (state, action) => {
        console.log(action.payload.data);
        console.log(typeof action.payload.data['0']);

        if (action?.payload?.data['0']) {
          state.selectedTemplateData = action?.payload?.data['0'];
        } else {
          if (action?.payload?.data?.typeOfOperation === 'DELETE') {
            state.selectedTemplateData = null;
          }
        }
      })
      .addCase(getTemplateDataById.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(createNewTemplate.pending, (state) => {})
      .addCase(createNewTemplate.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
      })
      .addCase(createNewTemplate.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editTemplateTitle.pending, (state) => {})
      .addCase(editTemplateTitle.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
        if (action.payload) {
          state.templatesList?.map((template: TemplateType) => {
            if (template?.id === action?.payload?.data?.id) {
              template.templateName = action?.payload?.data?.templateName;
              return template;
            }
          });
        }
      })
      .addCase(editTemplateTitle.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteTemplate.pending, (state) => {})
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.successMessage = action.payload.message;
        if (action.payload) {
          state.templatesList?.filter((template: TemplateType) => {
            return template?.id !== action?.payload?.data?.id;
          });
        }
      })
      .addCase(deleteTemplate.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const selectMasterDataState = ({ masterData }: RootState) => ({
  isError: masterData?.isError,
  errorMessage: masterData?.errorMessage,
  isSuccess: masterData?.isSuccess,
  successMessage: masterData?.successMessage,
  questionList: masterData?.questionList,
  candidateStatus: masterData?.candidateStatus,
  templatesList: masterData?.templatesList,
  selectedTemplateData: masterData?.selectedTemplateData,
  activeBuestionBankTab: masterData?.activeBuestionBankTab
});

export const {
  setIsError,
  setIsSuccess,
  setLocalTemplateData,
  emptySelectedTemplateData,
  setActiveQuestionBankTab
} = masterDataSlice.actions;
export default masterDataSlice.reducer;
