import { Box, Tooltip, Typography } from '@mui/material';

export type EventPropType = {
  event: any;
  onEdit?: any;
  onDelete?: any;
};

const CalenderEvent = ({ event, onEdit, onDelete }: EventPropType) => {
  const { extendedProps: props } = event;
  const associatedParties = ['Google', 'Future Tech', 'Tech Solution'];
  const subtitle = 'Javascript Developer';

  // TODO: Use when we have large title.
  // const eventLabel =
  //   event.eventTitle.length >= 20
  //     ? event.eventTitle.slice(0, 17) + '...'
  //     : event.eventTitle;
  return (
    <>
      <Box
        className={`${
          props.isRecuring ? 'recurring' : 'published'
        } eventBox flex-basic-center`}
      >
        <Box className="eventTitle flex-basic-center mt-1">
          {/* {event.eventTitle} */}
          {props.isRecuring ? 'Recurring Slot' : 'Published Slot'}
        </Box>
      </Box>
      {/* TODO: When Interview is scheduled */}
      {/* <Box className={`scheduled eventBox `}>
        <Box className="eventTitle mt-1">
          {getInterviewTitle(associatedParties)}
        </Box>
        <Box className="eventsubTitle mt-1">{subtitle}</Box>
      </Box> */}
    </>
  );
};

const getInterviewTitle = (values: string[]): JSX.Element => {
  return (
    <Box className="d-flex">
      {values?.length < 3 ? (
        values?.map((item, index) => (
          <Box key={index}>
            {item}
            {index + 1 < values?.length && (
              <span className="icon-arrow ml-5 mr-5"></span>
            )}
          </Box>
        ))
      ) : (
        <Box className="d-flex">
          {values && values[0]}
          {<span className="icon-arrow ml-5 mr-5 mt-3"></span>}
          {<span>...</span>}
          {<span className="icon-arrow ml-5 mr-5 mt-3"></span>}
          {values && values[values?.length - 1]}
        </Box>
      )}
    </Box>
  );
};

export default CalenderEvent;
