import React, { useEffect, useState } from 'react';
//Components
import TextField from '@mui/material/TextField';
import { Autocomplete, AutocompleteProps, Theme } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { MultiSelectOption } from '../../../modules/JD/models';

export type CombinedProps = AutocompleteProps<
  any,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
>;
export interface StyleProps {
  currentThemes: Theme;
}

const filter = createFilterOptions();

export const AutocompleteTagsCreate = React.forwardRef<any, CombinedProps>(
  ({ ...props }: CombinedProps, ref: any) => {
    const [selected, setSelected] = useState<MultiSelectOption[]>([]);
    const [options, setOptions] = useState<MultiSelectOption[]>([]);
    const [fullOptionList, setFullOptionList] = useState<MultiSelectOption[]>(
      []
    );

    useEffect(() => {
      const selectedValues: MultiSelectOption[] = props?.value
        ? props?.value
        : [];
      setSelected(selectedValues);

      const optionValues: MultiSelectOption[] = props.options
        ? (props.options as any[])
        : [];
      setFullOptionList(optionValues);
      setOptions(optionValues);
    }, []);

    useEffect(() => {
      const selectedValues: MultiSelectOption[] = props?.value
        ? props?.value
        : [];
      if (selectedValues.length > 0) {
        const filteredArray = fullOptionList.filter(function (
          array_el: MultiSelectOption
        ) {
          return (
            selectedValues.filter(function (anotherOne_el: MultiSelectOption) {
              return anotherOne_el.label == array_el.label;
            }).length == 0
          );
        });
        setOptions(filteredArray);
      }
      setSelected(selectedValues);
    }, [props.value]);

    useEffect(() => {
      const selectedValues: MultiSelectOption[] = [];
      const optionValues: MultiSelectOption[] = props.options
        ? (props.options as any[])
        : [];
      const res = optionValues.filter((item) => !selectedValues.includes(item));
      setFullOptionList(res);
      setOptions(optionValues);
    }, [props.options]);

    return (
      <Autocomplete
        className={props.className}
        value={selected}
        multiple
        onChange={(event, newValue, reason, details) => {
          let valueList = [...selected];
          if (details.option.create && reason !== 'removeOption') {
            valueList.push({
              id: undefined,
              label: details.option.name,
              create: details.option.create
            });
            setSelected(valueList);
            props?.onChange(event, valueList, reason, details);
          } else if (reason === 'createOption') {
            const newdetails = {
              option: { name: details.option, create: true }
            };
            valueList.push({
              id: undefined,
              label: newdetails.option.name,
              create: newdetails.option.create
            });
            setSelected(valueList);
            props?.onChange(event, valueList, reason, newdetails);
          } else {
            setSelected(newValue);
            props?.onChange(event, newValue, reason, details);
          }
        }}
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.label
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              name: inputValue,
              label: `Add "${inputValue}"`,
              create: true
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="tags-Create"
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.label) {
            return option.label;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => (
          <li {...props}>{option.create ? option.label : option.label}</li>
        )}
        freeSolo
        renderInput={props.renderInput}
        renderTags={props.renderTags}
      />
    );
  }
);
export default AutocompleteTagsCreate;
